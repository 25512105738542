import { appConstants } from '../_constants/app-constants';
import { Dispatch } from 'react';

const setIsShowMobileSidebar = (isShowMobileSidebar: boolean) => (
  dispatch: Dispatch<{ type: string; payload: boolean }>
) => {
  return dispatch({
    type: appConstants.SET_IS_SHOW_MOBILE_SIDEBAR,
    payload: isShowMobileSidebar,
  });
};

const setIsMobile = (isMobile: boolean) => (
  dispatch: Dispatch<{ type: string; payload: boolean }>
) => {
  return dispatch({ type: appConstants.SET_IS_MOBILE, payload: isMobile });
};
export const appActions = {
  setIsMobile,
  setIsShowMobileSidebar,
};
