import React, { useState } from 'react';
import { mailExpr } from '../../_shared/utils';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toastsActions } from '../../../_actions';
import styled from 'styled-components';
import { StyledFC } from '../../_shared/types';
import { useSignInMutation } from './queries';
import { authConstants } from '../../../_constants';
import SubmitButtonComponent from '../../_shared/Button';

const SignInForm_: StyledFC<{}> = ({ className }) => {
  const dispatch = useDispatch();
  const addToast = (toast: {
    text: string;
    className: string;
    delay: number;
  }) => dispatch(toastsActions.addToast(toast));

  const [fieldData, setFieldData] = useState({ email: '', password: '' });
  const [touched, setTouched] = useState({ email: false, password: false });
  const [signInHook] = useSignInMutation();

  const onBlur = (field: string) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (fieldData: { email: string; password: string }) => {
    const { email, password } = fieldData;
    return (
      !(email.length === 0 || !mailExpr.test(email)) && !(password.length === 0)
    );
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = fieldData;
    if (!email || !password) return;
    try {
      const result = await signInHook({
        variables: {
          email,
          password,
        },
      });
      const accessToken = result.data?.signIn.accessToken;
      const refreshToken = result.data?.signIn.refreshToken;
      dispatch({
        type: authConstants.FETCH_LOGIN_SUCCESS,
        payload: { accessToken, refreshToken },
      });
    } catch (error) {
      dispatch({ type: authConstants.FETCH_LOGIN_FAILURE, payload: error });
      if (error.message.toLowerCase().includes('Network error'.toLowerCase())) {
        addToast({
          text: `Ошибка соединения.`,
          className: 'bg-danger text-light',
          delay: 3000,
        });
      } else {
        addToast({
          text: `Неверный логин или пароль.`,
          className: 'bg-danger text-light',
          delay: 3000,
        });
      }
    }
  };

  const onChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldData({
      ...fieldData,
      [field]: e.target.value,
    });
  };

  const isEnabled = validate(fieldData);
  const { email, password } = fieldData;
  return (
    <form className={`auth-page-form ${className} w-100`} onSubmit={onSubmit}>
      <div
        className={`form-group w-100 email ${
          email.length > 0 ? ' hasLabel' : ''
        }`}
      >
        <input
          onChange={onChange('email')}
          onBlur={onBlur('email')}
          type="email"
          autoComplete="email"
          className={`form-control w-100 ${
            (email.length === 0 || !mailExpr.test(email)) && touched.email
              ? ' is-invalid'
              : ''
          }`}
          placeholder="Email"
          value={email}
        />
      </div>

      <div
        className={`form-group w-100 password ${
          password.length > 0 ? ' hasLabel' : ''
        }`}
      >
        <input
          onChange={onChange('password')}
          onBlur={onBlur('password')}
          type="password"
          autoComplete="current-password"
          className={`form-control w-100 ${
            password.length === 0 && touched.password ? ' is-invalid' : ''
          }`}
          placeholder="Пароль"
          value={password}
        />
      </div>

      <div className="w-100 d-flex justify-content-end">
        <Link to={'restore'} className="restore-password">
          <small>Забыли пароль?</small>
        </Link>
      </div>

      <SubmitButtonComponent label={'Войти'} disabled={!isEnabled} />

      <div className="link-container d-flex justify-content-center w-100">
        <Link to={'signup'} className="link">
          Создать аккаунт
        </Link>
      </div>
    </form>
  );
};

const SignInForm = styled(SignInForm_)`
  height: 320px;
  margin-top: 60px;
  .restore-password {
    small {
      font-size: 14px !important;
    }
  }
  .hasLabel::before {
    font-size: 14px !important;
  }
`;

export default SignInForm;
