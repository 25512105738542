import { FC, ReactElement } from 'react';
import {
  Assignment,
  Me,
  Employee,
  Achievement,
  AchievementWeight,
  AchievementColor,
  Category,
} from '../../_graphql/schema';

import { QueryResult } from '@apollo/react-common';

export type StyledFC<Props = {}> = FC<Props & { className?: string }>;

export const WeightsEnum: { [key: number]: string } = {
  100: 'bronze',
  200: 'silver',
  400: 'gold',
  800: 'platinum',
};

export type Toast = {
  id: number;
  delay: number;
  className: string;
  text: string;
};

export type TaggedAchievement = {
  assignmentImage: string;
  tag: string;
} & Achievement;

export type AchievementCategory = {
  name: string;
  achievements: TaggedAchievement[];
};

export type Weight = {
  id: number;
  value: number;
  name: string;
};

export type Link = {
  label: string;
  url: string;
  icon: string;
  index: number;
};

export type User = Me & {
  employee: Employee;
};

export type RootState = {
  app: {
    isMobile: boolean;
  };
};

export type Players = {
  team: Employee[];
  subscriptions: Employee[];
  other: Employee[];
};

export type QueryViewType = <TData, TVariables>(props: {
  result: QueryResult<TData, TVariables>;
  renderInit?: (result: QueryResult<TData, TVariables>) => ReactElement | null;
  renderData: (
    data: TData,
    result: QueryResult<TData, TVariables>
  ) => ReactElement | null;
  renderError?: (result: QueryResult<TData, TVariables>) => ReactElement | null;
}) => ReactElement | null;

export type CustomAssignmentOfPlayerPage = { __typename?: 'Assignment' } & Pick<
  Assignment,
  'createdAt' | 'image' | 'id'
> & {
    achievement: { __typename?: 'Achievement' } & Pick<
      Achievement,
      | 'id'
      | 'name'
      | 'description'
      | 'message'
      | 'image'
      | 'isActive'
      | 'animation'
    > & {
        weight: { __typename?: 'AchievementWeight' } & Pick<
          AchievementWeight,
          'name' | 'value'
        >;
        color: { __typename?: 'AchievementColor' } & Pick<
          AchievementColor,
          'name' | 'value'
        >;
        category: { __typename?: 'Category' } & Pick<Category, 'name'>;
      };
  };

export type shortAchivment = { __typename?: 'Achievement' } & Pick<
  Achievement,
  'id' | 'name' | 'description' | 'message' | 'image' | 'isActive' | 'animation'
> & {
    weight: { __typename?: 'AchievementWeight' } & Pick<
      AchievementWeight,
      'name' | 'value'
    >;
    color: { __typename?: 'AchievementColor' } & Pick<
      AchievementColor,
      'name' | 'value'
    >;
    category: { __typename?: 'Category' } & Pick<Category, 'name'>;
  };
