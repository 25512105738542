import { userConstants } from '../_constants';

const initialState: {
  data: any;
  error: any;
  loading: boolean;
} = {
  data: undefined,
  error: undefined,
  loading: true,
};
const user = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        // ...state,
        error: null,
        loading: true,
      };

    case userConstants.GET_USER_SUCCESS:
      return {
        data: action.payload,
        error: null,
        loading: false,
      };

    case userConstants.GET_USER_FAILURE:
      return {
        data: null,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default user;
