import * as Types from '../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetEventsIdsForEmployeeQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetEventsIdsForEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & { events: (
      { __typename?: 'EventPage' }
      & { items: Array<(
        { __typename: 'AssignmentEvent' }
        & Pick<Types.AssignmentEvent, 'id'>
      ) | (
        { __typename: 'MemoryEvent' }
        & Pick<Types.MemoryEvent, 'id' | 'image'>
        & { assignment: (
          { __typename?: 'Assignment' }
          & Pick<Types.Assignment, 'id'>
          & { achievement: (
            { __typename?: 'Achievement' }
            & Pick<Types.Achievement, 'id'>
          ) }
        ) }
      )> }
    ) }
  ) }
);

export type GetEmployeeQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'id' | 'name' | 'email' | 'internalEmail' | 'position' | 'isActive' | 'phone' | 'avatar'>
  ) }
);

export type GetMaybeAssignmentsQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetMaybeAssignmentsQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'name'>
    & { maybeAssignments: Array<(
      { __typename: 'Achievement' }
      & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'message' | 'image' | 'isActive' | 'animation'>
      & { weight: (
        { __typename?: 'AchievementWeight' }
        & Pick<Types.AchievementWeight, 'name' | 'value'>
      ), color: (
        { __typename?: 'AchievementColor' }
        & Pick<Types.AchievementColor, 'name' | 'value'>
      ), category: (
        { __typename?: 'Category' }
        & Pick<Types.Category, 'name'>
      ) }
    ) | (
      { __typename: 'Assignment' }
      & Pick<Types.Assignment, 'createdAt' | 'id'>
      & { assignmentImage: Types.Assignment['image'] }
      & { achievement: (
        { __typename?: 'Achievement' }
        & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'message' | 'image' | 'isActive' | 'animation'>
        & { weight: (
          { __typename?: 'AchievementWeight' }
          & Pick<Types.AchievementWeight, 'name' | 'value'>
        ), color: (
          { __typename?: 'AchievementColor' }
          & Pick<Types.AchievementColor, 'name' | 'value'>
        ), category: (
          { __typename?: 'Category' }
          & Pick<Types.Category, 'name'>
        ) }
      ) }
    )> }
  ) }
);


export const GetEventsIdsForEmployeeDocument = gql`
    query getEventsIdsForEmployee($id: Int!) {
  employee(id: $id) {
    events(take: 50) {
      items {
        __typename
        ... on MemoryEvent {
          id
          image
          assignment {
            id
            achievement {
              id
            }
          }
        }
        ... on AssignmentEvent {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsIdsForEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEventsIdsForEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsIdsForEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsIdsForEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventsIdsForEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEventsIdsForEmployeeQuery, GetEventsIdsForEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEventsIdsForEmployeeQuery, GetEventsIdsForEmployeeQueryVariables>(GetEventsIdsForEmployeeDocument, baseOptions);
      }
export function useGetEventsIdsForEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventsIdsForEmployeeQuery, GetEventsIdsForEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEventsIdsForEmployeeQuery, GetEventsIdsForEmployeeQueryVariables>(GetEventsIdsForEmployeeDocument, baseOptions);
        }
export type GetEventsIdsForEmployeeQueryHookResult = ReturnType<typeof useGetEventsIdsForEmployeeQuery>;
export type GetEventsIdsForEmployeeLazyQueryHookResult = ReturnType<typeof useGetEventsIdsForEmployeeLazyQuery>;
export type GetEventsIdsForEmployeeQueryResult = ApolloReactCommon.QueryResult<GetEventsIdsForEmployeeQuery, GetEventsIdsForEmployeeQueryVariables>;
export const GetEmployeeDocument = gql`
    query getEmployee($id: Int!) {
  employee(id: $id) {
    id
    name
    email
    internalEmail
    position
    isActive
    phone
    avatar
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, baseOptions);
      }
export function useGetEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, baseOptions);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = ApolloReactCommon.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetMaybeAssignmentsDocument = gql`
    query getMaybeAssignments($id: Int!) {
  employee(id: $id) {
    name
    maybeAssignments {
      __typename
      ... on Achievement {
        id
        name
        description
        message
        weight {
          name
          value
        }
        image
        color {
          name
          value
        }
        isActive
        animation
        category {
          name
        }
      }
      ... on Assignment {
        createdAt
        id
        assignmentImage: image
        achievement {
          id
          name
          description
          message
          weight {
            name
            value
          }
          image
          color {
            name
            value
          }
          isActive
          animation
          category {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMaybeAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetMaybeAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaybeAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaybeAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMaybeAssignmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMaybeAssignmentsQuery, GetMaybeAssignmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMaybeAssignmentsQuery, GetMaybeAssignmentsQueryVariables>(GetMaybeAssignmentsDocument, baseOptions);
      }
export function useGetMaybeAssignmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMaybeAssignmentsQuery, GetMaybeAssignmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMaybeAssignmentsQuery, GetMaybeAssignmentsQueryVariables>(GetMaybeAssignmentsDocument, baseOptions);
        }
export type GetMaybeAssignmentsQueryHookResult = ReturnType<typeof useGetMaybeAssignmentsQuery>;
export type GetMaybeAssignmentsLazyQueryHookResult = ReturnType<typeof useGetMaybeAssignmentsLazyQuery>;
export type GetMaybeAssignmentsQueryResult = ApolloReactCommon.QueryResult<GetMaybeAssignmentsQuery, GetMaybeAssignmentsQueryVariables>;