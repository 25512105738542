import * as Types from '../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetAchievementQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetAchievementQuery = (
  { __typename?: 'Query' }
  & { achievement: (
    { __typename: 'Achievement' }
    & Pick<Types.Achievement, 'animation' | 'image' | 'name' | 'description' | 'message'>
    & { weight: (
      { __typename?: 'AchievementWeight' }
      & Pick<Types.AchievementWeight, 'value'>
    ), color: (
      { __typename?: 'AchievementColor' }
      & Pick<Types.AchievementColor, 'value'>
    ), category: (
      { __typename?: 'Category' }
      & Pick<Types.Category, 'name'>
    ) }
  ) }
);

export type GetAssignmentQueryVariables = {
  employee: Types.Scalars['Int'];
  achievement: Types.Scalars['Int'];
};


export type GetAssignmentQuery = (
  { __typename?: 'Query' }
  & { assignment: (
    { __typename?: 'Assignment' }
    & Pick<Types.Assignment, 'createdAt' | 'updatedAt' | 'description' | 'image'>
    & { achievement: (
      { __typename?: 'Achievement' }
      & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'image'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Types.Category, 'name'>
        & { project: (
          { __typename?: 'Project' }
          & Pick<Types.Project, 'description'>
        ) }
      ) }
    ) }
  ) }
);

export type UpdateAssignmentMutationVariables = {
  employee: Types.Scalars['Int'];
  achievement: Types.Scalars['Int'];
  description?: Types.Maybe<Types.Scalars['String']>;
  image?: Types.Maybe<Types.Scalars['String']>;
};


export type UpdateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAssignment: (
    { __typename?: 'Assignment' }
    & Pick<Types.Assignment, 'updatedAt'>
  ) }
);


export const GetAchievementDocument = gql`
    query getAchievement($id: Int!) {
  achievement(id: $id) {
    __typename
    animation
    image
    name
    description
    message
    weight {
      value
    }
    color {
      value
    }
    category {
      name
    }
  }
}
    `;

/**
 * __useGetAchievementQuery__
 *
 * To run a query within a React component, call `useGetAchievementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchievementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchievementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAchievementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAchievementQuery, GetAchievementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAchievementQuery, GetAchievementQueryVariables>(GetAchievementDocument, baseOptions);
      }
export function useGetAchievementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAchievementQuery, GetAchievementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAchievementQuery, GetAchievementQueryVariables>(GetAchievementDocument, baseOptions);
        }
export type GetAchievementQueryHookResult = ReturnType<typeof useGetAchievementQuery>;
export type GetAchievementLazyQueryHookResult = ReturnType<typeof useGetAchievementLazyQuery>;
export type GetAchievementQueryResult = ApolloReactCommon.QueryResult<GetAchievementQuery, GetAchievementQueryVariables>;
export const GetAssignmentDocument = gql`
    query getAssignment($employee: Int!, $achievement: Int!) {
  assignment(employee: $employee, achievement: $achievement) {
    achievement {
      id
      name
      description
      image
      category {
        name
        project {
          description
        }
      }
    }
    createdAt
    updatedAt
    description
    image
  }
}
    `;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      employee: // value for 'employee'
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
      }
export function useGetAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const UpdateAssignmentDocument = gql`
    mutation updateAssignment($employee: Int!, $achievement: Int!, $description: String, $image: String) {
  updateAssignment(employee: $employee, achievement: $achievement, description: $description, image: $image) {
    updatedAt
  }
}
    `;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      employee: // value for 'employee'
 *      achievement: // value for 'achievement'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, baseOptions);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;