import { combineReducers } from 'redux';
import auth from './auth-reducer';
import client from './client-reducer';
import user from './user-reducer';
import restore from './restore-reducer';
import register from './register-reducer';
import app from './app-reducer';
import toasts from './toasts-reducer';

const reducer = combineReducers({
  app,
  auth,
  client,
  user,
  restore,
  register,
  toasts,
});

export default reducer;
export type AppStateType = ReturnType<typeof reducer>;
