import { userService } from '../_services';
import { userConstants } from '../_constants';
import { Dispatch } from 'redux';
import { Me } from '../_graphql/schema';

const getUser = () => (dispatch: Dispatch<any>) => {
  dispatch(request());
  return userService
    .getUser()
    .then(({ data }) => dispatch(success(data)))
    .catch((error) => dispatch(failure(error)));

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }

  function success(user: Me) {
    return { type: userConstants.GET_USER_SUCCESS, payload: user };
  }

  function failure(error: string) {
    return { type: userConstants.GET_USER_FAILURE, payload: error };
  }
};

const register = (email: string) => (dispatch: Dispatch<any>) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    userService
      .register(email)
      .then(() => {
        dispatch(success());
        resolve();
      })
      .catch((error) => {
        dispatch(failure(error));
        reject(error);
      })
  );

  function request() {
    return { type: userConstants.REGISTER_USER_REQUEST };
  }

  function success() {
    return { type: userConstants.REGISTER_USER_SUCCESS };
  }

  function failure(error: string) {
    return { type: userConstants.REGISTER_USER_FAILURE, payload: error };
  }
};

export const userActions = {
  getUser,
  register,
};
