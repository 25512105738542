import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { StyledFC, Link, User } from '../../_shared/types';
import defaultLogo from '../_shared/images/elements-avatar-male@2x.png';

const SideBar_: StyledFC<{
  user: User;
  links: Link[];
}> = ({ className, user, links }) => {
  const history = useHistory();
  const { location } = history;
  const prevHistory = history;
  const { location: prevLocation } = prevHistory;
  const [previousLinkIndex, setPreviousLinkIndex] = useState(-1);
  const didMountRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const link = links.find((l: Link) => location.pathname.includes(l.url));
    const idx = link ? link.index : 0;
    setPreviousLinkIndex(idx - 1);
  }, [links, location, setPreviousLinkIndex]);

  useEffect(() => {
    if (location && location.pathname && prevLocation && prevLocation.pathname)
      if (didMountRef.current) {
        if (prevLocation.pathname !== location.pathname) {
          const link = links.find((l: Link) =>
            location.pathname.includes(l.url)
          );
          const idx = link ? link.index : 0;
          setPreviousLinkIndex(idx - 1);
        }
      }
  }, [links, location, prevLocation]);

  const renderLinks = (links: Link[]) => {
    return links.map((link, idx) => (
      <NavLink
        key={link.index}
        className={`profile sidebar-item router-link outer pointer ${
          previousLinkIndex === link.index ? 'previous' : ''
        }
        ${link.url === '/nope' ? 'cherta' : ''} ${
          link.url === '/search-players' ? 'search-players' : ''
        }
        ${location.pathname.includes('/search-players/') ? 'white' : ''}`}
        activeClassName="active"
        to={link.url}
      >
        {link.url === '/nope' ? (
          <div className="cherta inner d-flex justify-content-center align-items-center">
            <span></span>
          </div>
        ) : (
          <div
            className={`d-flex flex-column justify-content-center align-items-center inner ${
              location.pathname.includes('/search-players/') ? 'white' : ''
            }`}
          >
            {link.url === '/player' ? (
              <div className="avatar overflow-hidden rounded-circle ">
                <img src={user.employee.avatar || defaultLogo} alt="profile" />
              </div>
            ) : (
              <>
                <i className={`badges pointer ${link.icon}`} />
              </>
            )}
          </div>
        )}
      </NavLink>
    ));
  };

  return (
    <aside
      className={`${className} d-sm-flex flex-column d-none`}
      ref={didMountRef}
    >
      <div
        className={`offset sidebar-item outer ${
          previousLinkIndex === -1 ? 'previous' : ''
        }`}
      >
        <div
          className={`d-flex flex-column justify-content-center align-items-center inner`}
        >
          <div className="avatar overflow-hidden rounded-circle"></div>
        </div>
      </div>
      {renderLinks(links)}
      <div
        className={`filler flex-grow-1 sidebar-item outer ${
          location.pathname.includes('/search-players/') ? 'white' : ''
        }`}
      >
        <div className={`inner`}></div>
      </div>
    </aside>
  );
};

const SideBar = styled(SideBar_)`
  width: ${({ theme }) => theme.sidebarWidth};
  .cherta {
    background-color: ${({ theme }) => theme.colors.paleGreyTwo};
    height: 50px !important;
    pointer-events: none;
    cursor: not-allowed !important;
    span {
      height: 2px;
      width: 60px;
      background-color: #d4dee9;
    }
  }
  .sidebar-item {
    transition: color 0.15s ease-in-out;
    color: ${({ theme }) => theme.colors.cloudyBlueText};
    outline: none;
    display: block;

    i.badges-badges {
      font-size: 29px;
    }

    &:nth-child(2) {
      height: 122px !important;
      div {
        height: 122px !important;
        .avatar {
          height: 50px !important;
        }
      }
    }

    :hover {
      color: ${({ theme }) => theme.colors.slateGreyTitleText};
    }
    .badges-friends-hover {
      font-size: 22px;
    }
    &.outer.previous {
      &.cherta {
        background-color: #f9fafd;
        &.white {
          background-color: ${({ theme }) => theme.colors.white};
        }
      }
      background-color: ${({ theme }) => theme.colors.white};

      .inner {
        border-bottom-right-radius: 20px;
      }
    }

    &.outer,
    .inner {
      width: 100%;
      height: 90px;
      background-color: ${({ theme }) => theme.colors.paleGreyTwo};
    }

    &.router-link {
      font-size: 40px;
      transition: color 0.15s ease-in-out;

      &.active {
        &.search-players {
          .inner {
            background-color: #f9fafd;
            &.white {
              background-color: ${({ theme }) => theme.colors.white};
            }
          }
          + .outer {
            background-color: #f9fafd;
            &.white {
              background-color: ${({ theme }) => theme.colors.white};
            }
          }
        }
        color: ${({ theme }) => theme.colors.periwinkle};

        i {
          color: ${({ theme }) => theme.colors.periwinkle};

          &::before {
            color: ${({ theme }) => theme.colors.periwinkle};
          }
        }

        .inner {
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
          background-color: ${({ theme }) => theme.colors.white};
        }

        + .outer {
          background-color: white;

          .inner {
            border-top-right-radius: 20px;
          }
        }
      }
    }

    i {
      outline: none;
    }
  }

  .filler {
    background-color: ${({ theme }) => theme.colors.paleGreyTwo};
    flex: 1;

    &.outer {
      height: unset;

      .inner {
        height: 100%;
      }
    }
  }

  .logout {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.cloudyBlueText};
  }

  .offset {
    height: 15px !important;
    .inner {
      height: 100%;
    }
  }
  .profile {
    .inner {
      .avatar {
        width: 50px;
        height: 50px;

        img {
          vertical-align: baseline;
          width: 100%;
        }
      }
    }
  }
`;

export default SideBar;
