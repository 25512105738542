import { QueryViewType } from './types';
import React from 'react';

export const QueryView: QueryViewType = ({
  result,
  renderInit,
  renderData,
  renderError,
}) => {
  const { data, error, loading } = result;
  return error ? (
    renderError ? (
      renderError(result)
    ) : (
      <p>{error.message}</p>
    )
  ) : data === undefined && loading ? (
    renderInit ? (
      renderInit(result)
    ) : (
      <p>Loading</p>
    )
  ) : data ? (
    renderData(data, result) || null
  ) : null;
};
