import React from 'react';
import { useSelector } from 'react-redux';
import ToastBox from './ToastBox';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { StyledFC, Toast } from '../_shared/types';
import { AppStateType } from '../../_reducers';

const ToastList_: StyledFC<{}> = ({ className }) => {
  const { toastList }: { toastList: Toast[] } = useSelector(
    (state: AppStateType) => state.toasts
  );

  return (
    <div className={`${className} toast-list container`}>
      <div className="row">
        <div className="col-sm-8 offset-sm-2 col-10 offset-1">
          {toastList?.map((toast) => {
            return <ToastBox key={toast.id} toast={toast} />;
          })}
        </div>
      </div>
    </div>
  );
};

const xsMedia = bp.down(
  'xs',
  undefined,
  `
    bottom: 20px;
  `
);

const ToastList = styled(ToastList_)`
  position: fixed;
  top: auto;
  bottom: 40px;
  right: 0;
  left: 0;
  z-index: 1200;

  ${xsMedia}
`;

export default ToastList;
