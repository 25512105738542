import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderMobile from '../_shared/HeaderMobile';
import { appActions } from '../../../_actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PlayerPage from '../PlayerPage';
import { Employee } from '../../../_graphql/schema';
import {
  useGetPlayersQuery,
  useSubscribeMutation,
  useUnsubscribeMutation,
} from './queries';
import { useGetAssignmentsByUserIdLazyQuery } from '../_shared/queries';
import { AppStateType } from '../../../_reducers';
import defaultAvatar from '../_shared/images/elements-avatar-male@2x.png';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { StyledFC } from '../../_shared/types';
import { DefaultTheme } from 'styled-components/macro';

const SearchPlayersPage_: StyledFC<{}> = ({ className }) => {
  const [isShowPlayer, setIsShowPlayer] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isShowColleague, setIsShowColleague] = useState(false);
  const [isShowMobileSearch, setIsShowMobileSearch] = useState(false);

  const [player, setCurrentPlayer] = useState<Employee>();
  const [isShowTeam] = useState(false);
  const [isShowSubscriptions] = useState(false);
  const [, setError] = useState('');

  //Redux
  const isMobile = useSelector((state: AppStateType) => state.app.isMobile);
  const dispatch = useDispatch();
  const setIsShowMobileSidebar = useCallback(
    (val) => dispatch(appActions.setIsShowMobileSidebar(val)),
    [dispatch]
  );

  const setPlayer = (player: Employee) => {
    if (player) {
      setIsShowPlayer(true);
      setCurrentPlayer(player);
      getAssignment({
        variables: {
          id: player.id,
        },
      });
    }
  };

  const {
    data: allPlayers,
    refetch: searchPlayerByString,
  } = useGetPlayersQuery({
    variables: { string: searchString },
  });

  const [
    getAssignment,
    { data: playerAssignments },
  ] = useGetAssignmentsByUserIdLazyQuery();

  const debounceSearch = AwesomeDebouncePromise(
    () => searchPlayerByString({ string: searchString }),
    500
  );
  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const string = e.target.value;
    setSearchString(string);
    debounceSearch();
  };

  const weights = useSelector(
    (state: AppStateType) => state.client.weightList?.weights
  );

  const [subscribeMutation] = useSubscribeMutation();
  const [unsubscribeMutation] = useUnsubscribeMutation();

  const onSubscribe = (id: number, subscribed: boolean) => {
    if (!subscribed) {
      subscribeMutation({
        variables: {
          id,
        },
      })
        .then((data) => {
          if (data) {
            searchPlayerByString({ string: searchString });
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      unsubscribeMutation({
        variables: {
          id,
        },
      })
        .then((data) => {
          if (data) {
            searchPlayerByString({ string: searchString });
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  let previous: (string | number)[] = [];
  if (player) {
    const inTeam = allPlayers?.team.items
      ? allPlayers?.team.items.findIndex((e) => e.id === player.id)
      : -1;
    const inSubscriptions = allPlayers?.subscriptions?.items
      ? allPlayers?.subscriptions.items.findIndex((e) => e.id === player?.id)
      : -1;
    const inOther = allPlayers?.other?.items
      ? allPlayers?.other.items.findIndex((e) => e.id === player?.id)
      : -1;

    if (inSubscriptions > -1) {
      if (inSubscriptions === 0) {
        previous.push('subscriptions-title');
      }

      if (
        //
        allPlayers !== undefined &&
        allPlayers?.subscriptions?.items?.length > 1 &&
        //
        inSubscriptions === allPlayers?.subscriptions.items.length - 1
      ) {
        previous.push(allPlayers?.subscriptions.items[inSubscriptions - 1].id);
      }

      if (
        inSubscriptions !== 0 &&
        allPlayers !== undefined &&
        inSubscriptions !== allPlayers?.subscriptions.items.length - 1
      ) {
        previous.push(allPlayers?.subscriptions.items[inSubscriptions - 1].id);
      }
    }

    if (inTeam > -1) {
      if (inTeam === 0) {
        previous.push('team-title');
      }

      if (
        allPlayers !== undefined &&
        allPlayers?.team.items.length > 1 &&
        //
        inTeam === allPlayers?.team.items.length - 1
      ) {
        previous.push(allPlayers?.team.items[inTeam - 1].id);
      }
      //
      if (
        inTeam !== 0 &&
        allPlayers !== undefined &&
        inTeam !== allPlayers?.team.items.length - 1
      ) {
        previous.push(allPlayers?.team.items[inTeam - 1].id);
      }
    }

    if (inOther > -1) {
      if (inOther === 0) {
        previous.push('other-title');
      }

      if (
        allPlayers !== undefined &&
        allPlayers?.other.items.length > 1 &&
        inOther === allPlayers?.other.items.length - 1
      ) {
        previous.push(allPlayers?.other.items[inOther - 1].id);
      }
      //
      if (
        inOther !== 0 &&
        allPlayers !== undefined &&
        inOther !== allPlayers?.other.items.length - 1
      ) {
        previous.push(allPlayers?.other.items[inOther - 1].id);
      }
    }
  }

  const subscriptions = (
    <>
      <div
        className={`${
          allPlayers?.subscriptions?.items?.length === 0 || searchString === ''
            ? 'd-none'
            : 'players-category'
        } ${
          previous.includes('subscriptions-title') && isShowPlayer
            ? 'item-rounded-bottom'
            : ''
        }`}
      >
        <div className="inner">Подписки</div>
      </div>
      {allPlayers?.subscriptions?.items?.map((p) => {
        return (
          <div
            className={`player-card pointer d-flex ${
              player && p.id === player?.id && isShowPlayer ? 'active' : ''
            } ${
              previous.includes(p.id) && isShowPlayer
                ? 'item-rounded-bottom'
                : ''
            }`}
            key={p.id}
            onClick={() => {
              setPlayer(p as Employee);
              setIsShowColleague(false);
            }}
          >
            <div className="inner">
              <div className="img-player">
                <img
                  className="h-100 w-100"
                  src={p.avatar || defaultAvatar}
                  alt="avatar"
                />
              </div>
              <div className="name-player d-flex align-items-center">
                <span>{p.name}</span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );

  const teammate = (
    <>
      <div
        className={`${
          allPlayers?.team?.items?.length === 0 || searchString === ''
            ? 'd-none'
            : 'players-category'
        } ${
          previous.includes('team-title') && isShowPlayer
            ? 'item-rounded-bottom'
            : ''
        }`}
      >
        <div className="inner">Команда</div>
      </div>
      {allPlayers?.team?.items?.map((p) => {
        return (
          <div
            className={`player-card d-flex pointer ${
              player && p.id === player.id && isShowPlayer ? 'active' : ''
            } ${
              previous.includes(p.id) && isShowPlayer
                ? 'item-rounded-bottom'
                : ''
            }`}
            key={p.id}
            onClick={() => {
              setPlayer(p as Employee);
              setIsShowColleague(false);
            }}
          >
            <div className="inner">
              <div className="img-player">
                <img
                  className="h-100 w-100"
                  src={p.avatar || defaultAvatar}
                  alt="avatar"
                />
              </div>
              <div className="name-player d-flex align-items-center">
                <span>{p.name}</span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );

  const other = (
    <>
      <div
        className={`players-category ${
          previous.includes('other-title') && isShowPlayer
            ? 'item-rounded-bottom'
            : ''
        }`}
      >
        <div className="inner">
          <span
            className={`${
              allPlayers?.other?.items?.length === 0 || searchString === ''
                ? 'd-none'
                : ''
            }`}
          >
            Все
          </span>
        </div>
      </div>
      {!isMobile ? (
        <>
          {allPlayers?.other?.items?.map((p) => {
            return (
              <div
                className={`player-card pointer d-flex ${
                  player && p.id === player.id && isShowPlayer ? 'active' : ''
                } ${
                  previous.includes(p.id) && isShowPlayer
                    ? 'item-rounded-bottom'
                    : ''
                }`}
                key={p.id}
                onClick={() => {
                  setPlayer(p as Employee);
                  setIsShowColleague(false);
                }}
              >
                <div className="inner">
                  <div className="img-player">
                    <img
                      className="h-100 w-100"
                      src={p.avatar || defaultAvatar}
                      alt="avatar"
                    />
                  </div>
                  <div className="name-player d-flex align-items-center">
                    <span>{p.name}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="other-players">
          {allPlayers?.other?.items?.map((p) => {
            return (
              <div
                className={`player-card d-flex ${
                  player && p.id === player.id && isShowPlayer ? 'active' : ''
                } ${
                  previous.includes(p.id) && isShowPlayer
                    ? 'item-rounded-bottom'
                    : ''
                }`}
                key={p.id}
                onClick={() => {
                  setPlayer(p as Employee);
                  setIsShowColleague(false);
                }}
              >
                <div className="inner">
                  <div className="img-player">
                    <img
                      className="h-100 w-100"
                      src={p.avatar || defaultAvatar}
                      alt="avatar"
                    />
                  </div>
                  <div className="name-player d-flex align-items-center">
                    <span>{p.name}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );

  const allFilterPlayers = (
    <>
      {subscriptions}
      {teammate}
      {other}
    </>
  );

  return (
    <div className={`${className} container-fluid h-100`}>
      {isMobile ? (
        <HeaderMobile
          classSpec={isShowPlayer ? 'd-none' : ''}
          containerWrapper={({ children }) => <div>{children}</div>}
          searchString={searchString}
          searchPlayer={true}
          isShowColleague={isShowColleague}
          onSearchStringChange={onSearchStringChange}
          setIsShowMobileSidebar={setIsShowMobileSidebar}
          setIsShowMobileSearch={setIsShowMobileSearch}
          isShowMobileSearch={isShowMobileSearch}
          setIsShowColleague={setIsShowColleague}
        />
      ) : (
        ''
      )}
      <div className="row h-100 colleague-block-row flex-nowrap">
        <div
          className={[
            `col-lg-4 colleague-block-col`,
            !isShowColleague && isMobile ? 'hidden-colleague-block' : '',
          ].join(' ')}
        >
          <div className="title">Коллеги</div>
          <div className="search">
            <div className="form-group has-search">
              <i className="badges badges-search" />
              <input
                className="form-control"
                placeholder="Найти коллег"
                type="text"
                onChange={onSearchStringChange}
                value={searchString}
              />
              <i
                className={`badges badges-cross ${
                  searchString?.length ? ' visible' : ''
                }`}
                onClick={() => {
                  setSearchString('');
                  searchPlayerByString({ string: '' });
                }}
              />
            </div>
          </div>

          <div
            className={
              searchString?.length !== 0
                ? 'withoutButton players-card-block'
                : 'players-card-block'
            }
          >
            {searchString?.length !== 0
              ? allFilterPlayers
              : isShowTeam && !isShowSubscriptions
              ? teammate
              : subscriptions}
            <div className={`the-lastest-item`}>
              <div className="inner" />
            </div>
          </div>
        </div>
        <div
          className="col-lg-8 colleague-events-col justify-content-center"
          //
          style={
            isShowColleague && isMobile
              ? { display: 'none', overflowY: 'scroll' }
              : { overflowY: 'scroll' }
          }
        >
          {player && playerAssignments && isShowPlayer ? (
            <div>
              <PlayerPage
                user={player}
                achievements={playerAssignments?.employee.assigned.items}
                weights={weights}
                notMe
                onSubscribe={onSubscribe}
                onClosePlayer={(value) => {
                  setIsShowColleague(true);
                  setIsShowPlayer(value);
                }}
                subscribed={
                  allPlayers?.subscriptions.items
                    ? !!allPlayers?.subscriptions.items.find(
                        (s) => s.id === player.id
                      )
                    : false
                }
              />
            </div>
          ) : (
            <>
              <div className="title-event">Обновления</div>
              <div className="row">{}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const xsMedia = (theme: DefaultTheme) =>
  bp.down(
    'xs',
    undefined,
    `
      header {
        height: ${theme.headerHeightMobile};

        i {
          font-size: 19px;
          color: ${theme.colors.cloudyBlueText};
        }

        .title {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: ${theme.colors.cloudyBlueText};
          display: block;
        }
      }
      .button-profile {
        margin: 21px auto;
        margin-bottom: 0;
        justify-content: center;
      }
      .colleague-events-col {
        & > div {
          padding-right: 0;
          padding-left: 0;
        }
        .row {
          max-height: calc(100% - 110px);
        }
        .title-event {
          display: none;
        }
        .button-profile {
          margin-bottom: 10px;
        }
        .event-card {
          margin-left: 30px !important;
          margin-right: 30px;
          .events-achievements {
            margin-left: 50px;
          }
        }
      }
        overflow-y: unset !important;
        .colleague-block-row {
          flex-direction: column;
          max-height: 100%;
          .colleague-block-col {
            padding-left: 20px !important;
            padding-right: 20px !important;
            min-width: inherit !important;
            .title {
              display: none;
            }
            .players-card-block {
              max-height: calc(100% - 71px);
              &.withoutButton {
                max-height: 100%;
              }
            }
          }
          .search {
            display: none;
          }
        }
    `
  );

const mdMedia = bp.down(
  'md',
  undefined,
  `
    .colleague-events-col {
      .player-page-wrapper {
        padding: 0;
      }
      .event-card {
        .events-info {
          display: none;
        }
        .events-achievements {
          margin-left: 50px;
        }
      }
    }
        overflow-y: scroll;
  `
);

const lgMedia = bp.down(
  'lg',
  undefined,
  `
        .colleague-block-col {
          background-color: #f9fafd;
        }
    `
);

const SearchPlayersPage = styled(SearchPlayersPage_)`
  .header {
    height: unset;
  }

    .colleague-block-row {
      max-height: 100%;
      position: relative;
      overflow: hidden;

      .hidden-colleague-block {
        display: none;
      }
      .colleague-block-col {
        padding-right: 0;
        padding-left: 115px;
        min-width: 515px;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        height: 100%;
        background-color: #f9fafd;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        .title {
          margin-top: 35px;
          margin-bottom: 18px;
          font-size: 36px;
          font-weight: bold;
          color: #b0b6c9;
        }
        .search {
          .has-search {
            position: relative;
            margin-right: 10px;
            input {
              max-width: 300px;
              padding-left: 60px;
              height: 40px;
              background-color: ${({ theme }) => theme.colors.white};
              box-shadow: 0 6px 30px 2px rgba(66, 114, 172, 0.05) !important;
            }
            i {
              position: absolute;
              top: 0;
              display: block;
              width: 60px;
              height: 41px;
              line-height: 41px;
              text-align: center;
              color: #b7bfca;
              &.badges-search {
                left: 5px;
              }
              &.badges-cross {
                cursor: pointer;
                right: 96px !important;
                font-size: 10px;
                display: none;

                &.visible {
                  display: block;
                }
              }
            }
          }
        }
      }
      .button-profile {
        display: flex;
        width: 100%;
        .wrapper {
          max-width: 300px;
          width: 100%;
          display: flex;
          flex-grow: 1;
        }
        background-color: ${({ theme }) => theme.colors.white};
        width: 100%;

        .inner {
          display: flex;
          padding-bottom: 20px;
          flex-grow: 1;
          background-color: #f9fafd;
        }

        .active {
          background-color: #b7bfca;
          color: ${({ theme }) => theme.colors.white} !important;
        }
        .button-left-trigger {
          width: 50%;
          height: 30px;
          border-bottom-left-radius: 14.5px;
          border-top-left-radius: 14.5px;
          border: 1px solid #b7bfca;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #a9b1bc;
          font-size: 18px;
        }
        .button-right-trigger {
          width: 50%;
          height: 30px;
          border-bottom-right-radius: 14.5px;
          border-top-right-radius: 14.5px;
          border: 1px solid #b7bfca;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: #a9b1bc;
        }
      }
      .other-players-block {
        .players-category {
          .inner {
            padding-top: 40px;
          }
        }
      }
      .players-card-block {
        flex-grow: 1;
        height: 100%;
        max-height: calc(100% - 213px);
        .scrollbar-container {
          width: 100%;

          > .players-category {
            &:first-of-type {
              margin-top: 50px;
            }
          }
          .ps__rail-y {
            z-index: 1;
          }
        }

        .players-category {
          display: flex;
          font-size: 24px;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.slateGreyTitleText};
          background-color: ${({ theme }) => theme.colors.white};

          &:not(:first-of-type) {
            .inner {
              padding-top: 40px;
            }
          }

          .inner {
            display: flex;
            flex-grow: 1;
            background-color: #f9fafd;
            padding-left: 20px;
            padding-bottom: 10px;
            min-height: 20px;
          }
        }
      }
      .player-card {
        background-color: ${({ theme }) => theme.colors.white};
        position: relative;
        .inner {
          display: flex;
          flex-grow: 1;
          padding: 20px 15px;
          background-color: #f9fafd;
        }
        &.active {
          background-color: #f9fafd;

          .inner {
            background-color: ${({ theme }) => theme.colors.white};
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
          }

          .name-player {
            span {
              color: ${({ theme }) => theme.colors.slateGreyTitleText};
            }
          }

          + div {
            .inner {
              border-top-right-radius: 20px;
            }

            &.d-none + div {
              .inner {
                border-top-right-radius: 20px;
              }
            }
          }
        }
        .img-player {
          margin-right: 25px;
          min-width: 60px;
          min-height: 60px;
          width: 60px;
          overflow: hidden;
          border-radius: 50%;
          img {
            object-fit: contain;
          }
        }
        .name-player {
          span {
            font-size: 18px;
            letter-spacing: normal;
            color: ${({ theme }) => theme.colors.cloudyBlueText};
          }
        }
      }
      .colleague-events-col {
        max-height: 100%;
        height: 100%;
        min-height: 100%;
        overflow: hidden;
        position: relative;
        .title-event {
          margin-top: 35px;
          margin-bottom: 40px;
          font-size: 36px;
          font-weight: bold;
          color: #b0b6c9;
          margin-left: 60px;
        }
        .event-card {
          margin-bottom: 20px;
          padding: 20px 30px 20px 15px;
          margin-left: 60px;
          border-radius: 20px;
          &:hover {
            background-color: ${({ theme }) => theme.colors.paleGrey};
            .events-achievements {
              .achievement-count {
                background-color: ${({ theme }) => theme.colors.white};
              }
            }
          }
          .evets-player-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 15px;
          }
          .events-info {
            flex: 1;
            .events-date {
              font-size: 14px;
              line-height: 1.57;
              color: ${({ theme }) => theme.colors.cloudyBlueText};
            }
            .events-player-name {
              font-size: 18px;
              color: #8a9499;
            }
          }
          .events-achievements {
            display: flex;
            align-items: center;
            .achievement-img {
              width: 50px;
              height: 50px;
              border-radius: 10px;
            }
            .achievement-count {
              width: 50px;
              height: 50px;
              border-radius: 10px;
              background-color: ${({ theme }) => theme.colors.paleGrey};
              justify-content: center;
              align-items: center;
              font-size: 18px;
              color: ${({ theme }) => theme.colors.slateGreyTitleText};
              span {
                position: relative;
                &::before {
                  position: absolute;
                  content: '+';
                  right: 60%;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
      }
    }
  .item-rounded-top {
    .inner {
      border-top-right-radius: 20px;
    }
  }
  .item-rounded-bottom {
    .inner {
      border-bottom-right-radius: 20px;
    }
  }
  .the-lastest-item {
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    height: 20px;
    .inner {
      display: flex;
      flex-grow: 1;
      background-color: #f9fafd;
    }
  }

  ${({ theme }) => xsMedia(theme)}
  ${mdMedia}
  ${lgMedia}
`;

export default SearchPlayersPage;
