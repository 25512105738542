import { Redirect, Route } from 'react-router-dom';
import React, { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType } from '../_reducers';

const ProtectedRoute: FC<{
  component: ComponentType<any>;
  path: string;
}> = ({ component: Component, path }) => {
  const { accessToken } = useSelector((state: AppStateType) => state.auth);

  return (
    <Route
      path={path}
      render={(props) => {
        return accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/auth/'} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
