export enum clientConstants {
  ACHIEVEMENT_LIST_REQUEST = 'ACHIEVEMENT_LIST_REQUEST',
  ACHIEVEMENT_LIST_SUCCESS = 'ACHIEVEMENT_LIST_SUCCESS',
  ACHIEVEMENT_LIST_FAILURE = 'ACHIEVEMENT_LIST_FAILURE',

  WEIGHT_LIST_REQUEST = 'WEIGHT_LIST_REQUEST',
  WEIGHT_LIST_SUCCESS = 'WEIGHT_LIST_SUCCESS',
  WEIGHT_LIST_FAILURE = 'WEIGHT_LIST_FAILURE',

  PLAYERS_LIST_REQUEST = 'PLAYERS_LIST_REQUEST',
  PLAYERS_LIST_SUCCESS = 'PLAYERS_LIST_SUCCESS',
  PLAYERS_LIST_FAILURE = 'PLAYERS_LIST_FAILURE',
}
