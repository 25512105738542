import React, { useEffect } from 'react';
import { toastsActions } from '../../../_actions';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { StyledFC, Toast } from '../../_shared/types';
import { useDispatch } from 'react-redux';

const ToastBox_: StyledFC<{
  toast: Toast;
}> = ({ className, toast }) => {
  const dispatch = useDispatch();
  const removeToast = (id: number) => dispatch(toastsActions.removeToast(id));

  useEffect(() => {
    const timeout = setTimeout(() => removeToast(toast.id), toast.delay);
    return () => clearTimeout(timeout);
  });

  return (
    <div
      className={`${className} app-toast toast show ${
        toast.className ? toast.className : ''
      }`}
    >
      <div className="toast-body">
        <div>{toast.text}</div>
      </div>
    </div>
  );
};

const xsMedia = bp.down(
  'xs',
  undefined,
  `
    .toast-body {
      padding: 33px;
      font-size: 16px;
      font-weight: 600;

      > * {
        font-size: 16px;
        font-weight: 600;
      }
    }
  `
);

const ToastBox = styled(ToastBox_)`
  width: 100%;
  height: 60px;
  border-radius: 50px;
  max-width: unset;
  display: flex !important;
  position: relative;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;

  .toast-body {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    text-align: center;
  }

  &.bg-violet {
    background-color: #887cfd !important;
  }

  ${xsMedia}
`;

export default ToastBox;
