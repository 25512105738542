import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AchievementPage } from '../AchievementPage';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import {
  CustomAssignmentOfPlayerPage,
  shortAchivment,
  StyledFC,
} from '../../../_shared/types';
import { AppStateType } from '../../../../_reducers';

const AssignmentCard_: StyledFC<{
  assignment: CustomAssignmentOfPlayerPage;
  achievements: shortAchivment[];
  isMe: boolean;
  userId?: number;
}> = ({ assignment, className, achievements, isMe, userId }) => {
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const withMemory = true;
  const withShadow = assignment.image;
  const isMobile = useSelector((state: AppStateType) => state.app.isMobile);

  const imageSrc = assignment.achievement.image?.includes(
    String(process.env.REACT_APP_IMAGE_PATH)
  )
    ? assignment.achievement.image
    : String(process.env.REACT_APP_IMAGE_PATH) + assignment.achievement.image;

  return (
    <div className={className}>
      <div
        className={`wrapper d-flex flex-column align-items-center h-100 pointer event-locked ${
          hovered ? 'hovered' : ''
        }`}
      >
        {withMemory ? (
          <>
            <div className="image-wrapper position-relative">
              {withShadow && <div className="with-memory" />}
              <img src={imageSrc} alt="logo" />
            </div>
          </>
        ) : (
          <>
            <div className="not-received-wrapper position-relative">
              <div className="not-received-reward d-flex flex-column align-items-center justify-content-center">
                <img
                  src={imageSrc}
                  alt="logo"
                  className={withMemory ? '' : 'desaturate'}
                />
              </div>
            </div>
          </>
        )}
        <div
          className="name text-center"
          style={{
            visibility: hovered && !isMobile ? 'unset' : 'hidden',
            color: hovered && !isMobile ? '#a9b1bc' : '#a9b1bc',
            position: hovered && !isMobile ? 'relative' : 'inherit',
            top: hovered && !isMobile ? '5px' : 'unset',
          }}
        >
          {assignment.achievement.name}
        </div>
      </div>
      <div
        className="hoverplate"
        onMouseOver={() => setHovered(!hovered)}
        onMouseLeave={() => setHovered(!hovered)}
        onClick={() => setShowModal(!showModal)}
      >
        {' '}
      </div>
      {showModal ? (
        <AchievementPage
          id={assignment.achievement.id}
          achievements={achievements}
          isMe={isMe}
          userId={userId}
          onClose={() => setShowModal(!showModal)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const xsMedia = bp.down(
  'xs',
  undefined,
  `
    .wrapper {
      .name {
        line-height: 14px;
        font-size: 12px;
        min-height: calc(14px * 2);
        height: calc(14px * 2);
      }

      .not-received-wrapper {
        .not-received-reward {
          font-size: 50px;
        }
      }
    }
  `
);

const AssignmentCard = styled(AssignmentCard_)`
  position: relative;

  .event-locked * {
    pointer-events: none;
  }

  .wrapper {
    padding-bottom: 0;
    border-radius: 30px;
    outline: none;
    bottom: 0;
    &.hovered {
      position: relative;
      bottom: 5px;
      transition: bottom 0.2s;
    }

    .image-wrapper {
      position: relative;
      .with-memory {
        position: absolute;
        transform: rotate(10deg) translate(18px, -12px);
        width: 95%;
        height: 95%;
        background-color: ${({ theme }) => theme.colors.paleGreyTwo};
        border-radius: 20px;
      }

      img {
        position: relative;
        width: 105%;
        object-fit: contain;
        z-index: 2 !important;
        &.desaturate {
          opacity: 0.5;
          filter: grayscale(100%) brightness(150%) contrast(300%);
        }
      }

      .reward {
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        bottom: 12px;
        right: 12px;
        background: ${({ theme }) => theme.colors.white};
        font-size: 23px;

        .color-bronze {
          color: ${({ theme }) => theme.colors.bronzeColor};
        }

        .color-silver {
          color: ${({ theme }) => theme.colors.silverColor};
        }

        .color-gold {
          color: ${({ theme }) => theme.colors.goldColor};
        }

        .color-platinum {
          color: ${({ theme }) => theme.colors.platinumColor};
        }
      }
    }

    .not-received-wrapper {
      background-color: #eceff9;
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      border-radius: 30px;
      img {
        position: relative;
        width: 105%;
        object-fit: contain;
        z-index: 2 !important;
        &.desaturate {
          opacity: 0.5;
          filter: grayscale(100%) brightness(150%) contrast(300%);
        }
      }

      .another {
        width: 105%;
        object-fit: contain;
      }

      .not-received-reward {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 80px;
        color: ${({ theme }) => theme.colors.white};
      }
    }

    .name {
      margin-top: 5px;
      font-size: 16px;
      min-height: calc(18px * 3);
      font-weight: 600;
      line-height: 1;
    }
  }

  .hoverplate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  ${xsMedia}
`;

export default AssignmentCard;
