import * as Types from '../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type SignRestoreMutationVariables = {
  email: Types.Scalars['String'];
};


export type SignRestoreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'signRestore'>
);


export const SignRestoreDocument = gql`
    mutation signRestore($email: String!) {
  signRestore(email: $email)
}
    `;

/**
 * __useSignRestoreMutation__
 *
 * To run a mutation, you first call `useSignRestoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRestoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRestoreMutation, { data, loading, error }] = useSignRestoreMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSignRestoreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignRestoreMutation, SignRestoreMutationVariables>) {
        return ApolloReactHooks.useMutation<SignRestoreMutation, SignRestoreMutationVariables>(SignRestoreDocument, baseOptions);
      }
export type SignRestoreMutationHookResult = ReturnType<typeof useSignRestoreMutation>;
export type SignRestoreMutationResult = ApolloReactCommon.MutationResult<SignRestoreMutation>;
export type SignRestoreMutationOptions = ApolloReactCommon.BaseMutationOptions<SignRestoreMutation, SignRestoreMutationVariables>;