import React, { useCallback } from 'react';
import styled from 'styled-components';
import Icon from '../../_shared/Icon';
import defaultLogo from '../_shared/images/elements-avatar-male@2x.png';
import { StyledFC } from '../../_shared/types';
import { Employee } from '../../../_graphql/schema';
import { useDispatch } from 'react-redux';
import { appActions, authActions } from '../../../_actions';
import { useGetMyEmployeeIdQuery } from '../_shared/queries';
import { useRouteMatch } from 'react-router-dom';
import { gridArea } from './dom';

interface ProfilePageHeaderInterface {
  user: Employee;
  onClosePlayer?: (q: boolean) => void;
  subscribed: boolean;
  onSubscribe?: (id: number, subscribed: boolean) => void;
  area: string;
}

const _Avatar: StyledFC<{
  src: string;
  area: string;
  alt: string;
}> = ({ src, area, className, alt }) => {
  return <img src={src} className={className} alt={alt} />;
};

const Avatar = styled(_Avatar)`
  width: 120px;
  height: 120px;
  ${gridArea()};
  @media (${(props) => props.theme.mediaMobile}) {
    width: 80px;
    height: 80px;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const _NameAndPosition: StyledFC<{
  area: string;
  name: string;
  position: string;
}> = ({ name, area, position, className }) => {
  return (
    <div className={className}>
      <div className="name">{name}</div>
      <div className="position">{position}</div>
    </div>
  );
};

const NameAndPosition = styled(_NameAndPosition)`
  ${gridArea()};
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
    font-size: 28px;
    line-height: 27px;
    color: rgb(86, 92, 98);
    margin-bottom: 5px;
    font-weight: bold;
  }
  .position {
    ont-size: 18px;
    line-height: 22px;
    color: rgb(181, 191, 203);
  }
  @media (${(props) => props.theme.mediaMobile}) {
    text-align: center;
    .name {
      font-size: 16px;
      color: rgb(169, 177, 188);
      font-weight: bold;
      margin-top: 5px;
    }
    .position {
      display: none;
    }
  }
`;

const _Info: StyledFC<{ subdivision: string; email: string; area: string }> = ({
  subdivision,
  email,
  area,
  className,
}) => {
  return (
    <div className={className}>
      <div style={{ gridArea: 'member-icon' }}>
        <Icon name="icons-work-department" />
      </div>
      <div style={{ gridArea: 'subdivision' }}>
        <span>{subdivision}</span>
      </div>
      <div style={{ gridArea: 'mail-icon' }}>
        <Icon name="email" />
      </div>
      <div style={{ gridArea: 'mail' }}>
        <span>{email}</span>
      </div>
    </div>
  );
};

const Info = styled(_Info)`
  ${gridArea()};
  display: grid;
  grid:
    'member-icon  subdivision' min-content
    'mail-icon    mail' min-content
    / 22px auto;
  grid-column-gap: 20px;
  font-size: 18px;
  color: rgb(181, 191, 203);
  @media (${(props) => props.theme.mediaMobile}) {
    span {
      font-size: 12px;
    }
  }
`;

const TwoIcons = styled.div`
  display: none;
  grid-area: icons;
  width: 100%;
  height: 50px;
  color: rgb(169, 177, 188);

  @media (${(props) => props.theme.mediaMobile}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ExitButton = styled.div`
  ${gridArea()};
  display: flex;
  align-items: center;
  color: rgb(248, 120, 128);
  font-size: 18px;
  cursor: pointer;
  @media (${(props) => props.theme.mediaMobile}) {
    display: none;
  }
`;

const Header: StyledFC<ProfilePageHeaderInterface> = ({
  user,
  onClosePlayer,
  subscribed,
  onSubscribe,
  className,
}) => {
  const dispatch = useDispatch();
  const setIsShowMobileSidebar = useCallback(
    (val) => dispatch(appActions.setIsShowMobileSidebar(val)),
    [dispatch]
  );
  const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);

  const match = useRouteMatch();
  const fromSearchPlayers = match?.path === '/search-players';

  const { data: hookData } = useGetMyEmployeeIdQuery();

  const itsMe = user.id === hookData?.me.employee?.id;

  return (
    <div className={className}>
      <TwoIcons>
        {fromSearchPlayers ? (
          <Icon
            name="left"
            onClick={() => onClosePlayer && onClosePlayer(true)}
          />
        ) : (
          <Icon name="menu" onClick={() => setIsShowMobileSidebar(true)} />
        )}
        <Icon name="filter-small" />
      </TwoIcons>
      <Avatar src={user.avatar || defaultLogo} area="avatar" alt={user.name} />
      <NameAndPosition name={user.name} position={user.position} area="name" />
      <Info
        email={user.email}
        subdivision={user.subdivision.name}
        area="info"
      />
      {itsMe && (
        <ExitButton onClick={logout} area="exit">
          <span>Выйти из аккаунта</span>
        </ExitButton>
      )}
    </div>
  );
};

const ProfilePageHeaders = styled(Header)`
  ${gridArea()};
  padding-top: 55px;
  position: relative;
  width: 100%;
  display: grid;
  grid:
    'avatar       name          name' 120px
    'info  info   exit' auto
    / 120px auto min-content;
  gap: 25px;

  @media (${(props) => props.theme.mediaMobile}) {
    position: relative;
    width: 100%;
    padding-top: 50px;
    grid-gap: 5px;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 100%;
    grid-template-areas:
      'icons'
      'avatar'
      'name'
      'info';
  }
`;

export default ProfilePageHeaders;
