import React from 'react';
import styled from 'styled-components';
import Icon from '../../_shared/Icon';
import { StyledFC } from '../../_shared/types';

const _AwardIconBlock: StyledFC<{
  awardsCount: {
    gold: number;
    silver: number;
    bronze: number;
    platinum: number;
  };
}> = ({ className, awardsCount }) => {
  return (
    <div className={className}>
      <div className="item">
        <Icon name={'reward-platinum'} />
        <span>{awardsCount.platinum || 0}</span>
      </div>
      <div className="item">
        <Icon name={'reward-gold'} />
        <span>{awardsCount.gold || 0}</span>
      </div>
      <div className="item">
        <Icon name={'reward-silver'} />
        <span>{awardsCount.silver || 0}</span>
      </div>
      <div className="item">
        <Icon name={'reward-bronze'} />
        <span>{awardsCount.bronze || 0}</span>
      </div>
    </div>
  );
};

const AwardIconBlock = styled(_AwardIconBlock)`
  grid-area: icons;
  display: flex;
  font-size: 25px;
  color: rgb(181, 191, 203);

  .item {
    margin-right: 50px;
  }

  span {
    margin-left: 5px;
    font-size: 16px;
  }

  @media (max-width: 900px) {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (${(props) => props.theme.mediaMobile}) {
    justify-content: space-between;
    width: 100%;
    .item {
      margin-right: 0px;
    }
  }
`;

export default AwardIconBlock;
