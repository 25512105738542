import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { StyledFC } from '../../../../_shared/types';

const PlayerEventHeader_: StyledFC<{
  date: Date;
  type: string;
}> = ({ className, date, type }) => {
  return (
    <div className={`col ${className} d-flex align-items-center`}>
      <i className="badges badges-badges"> </i>
      <div className="info-block">
        <div className="date">{moment(date).format('DD.MM.YYYY')}</div>
        <div className="event-type">
          Новое {type === 'AssignmentEvent' ? 'достижение' : 'воспоминание'}
        </div>
      </div>
    </div>
  );
};

const xsMedia = bp.down(
  'xs',
  undefined,
  `
    .event-type {
      color: #565c62 !important;
    }
  `
);

const PlayerEventHeader = styled(PlayerEventHeader_)`
  margin-bottom: 23px;
  i {
    font-size: 26px;
    color: #99a1ab;
    margin-right: 25px;
  }
  .info-block {
    .date {
      color: ${({ theme }) => theme.colors.cloudyBlueText};
      font-size: 12px;
    }
    .event-type {
      font-size: 18px;
      line-height: 1.22;
      color: ${({ theme }) => theme.colors.componentColor};
    }
  }

  ${xsMedia}
`;

export default PlayerEventHeader;
