import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../_styled/theme';
import Icon from '../../_shared/Icon';
import SearchInput from '../../_shared/SearchInput';
import { StyledFC } from '../../_shared/types';
import { gridArea } from '../PlayerPage/dom';
import AwardIconBlock from './AwardIconBlock';

const _Header: StyledFC<{
  area: string;
  isAlbumActive: boolean;
  setAlbumActive: (a: boolean) => void;
  onSearchStringChange: (e: string) => void;
  onClickMenu: (a: boolean) => void;
  searchString: string;
  awardsCount: {
    gold: number;
    silver: number;
    bronze: number;
    platinum: number;
  };
}> = ({
  className,
  isAlbumActive,
  setAlbumActive,
  onSearchStringChange,
  searchString,
  awardsCount,
  onClickMenu,
}) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);

  return (
    <div className={className}>
      <div className="title">
        {!showSearch && <span>Достижения</span>}
        {showSearch && (
          <div className="mobile-search">
            <SearchInput onChange={onSearchStringChange} value={searchString} />
          </div>
        )}
      </div>
      <div className="search">
        <SearchInput onChange={onSearchStringChange} value={searchString} />
      </div>
      {!showSearch && (
        <div className="icons">
          <AwardIconBlock awardsCount={awardsCount} />
        </div>
      )}
      <div className="switcher">
        <div className="switchContainer pointer">
          <div
            className="album"
            style={{
              color: isAlbumActive ? 'rgb(86, 92, 98)' : undefined,
            }}
            onClick={() => setAlbumActive(true)}
          >
            Альбом
          </div>
          <div
            className="category"
            style={{
              color: !isAlbumActive ? 'rgb(86, 92, 98)' : undefined,
            }}
            onClick={() => setAlbumActive(false)}
          >
            По категориям
          </div>
          <div className="a-underline">
            {isAlbumActive && <div className="underline" />}
          </div>
          <div className="c-underline">
            {!isAlbumActive && <div className="underline" />}
          </div>
        </div>
      </div>

      <div className="menu-icon" onClick={() => onClickMenu(true)}>
        <Icon name="menu" />
      </div>

      <div className="search-icon" onClick={() => setShowSearch(!showSearch)}>
        <Icon name={showSearch ? 'cross' : 'search'} />
      </div>
    </div>
  );
};

const Header = styled(_Header)`
  ${gridArea()};
  width: 100%;
  display: grid;
  grid:
    'title search' min-content
    'icons switcher' min-content
    / 1fr 1fr;
  grid-gap: 40px;
  .title {
    grid-area: title;
    font-size: 36px;
    font-weight: 600;
    color: ${theme.colors.cloudyBlueText};
    -webkit-box-align: center;    
    text-align: left;
  }
  .icons {
    grid-area: icons;
  }
  .search {
    grid-area: search;
    display: flex;
    justify-content: flex-end;
  }
  .switcher {
    grid-area: switcher;
    justify-content: flex-end;
    display: flex;
    font-size: 18px;
    color: ${theme.colors.cloudyBlueText};
    .switchContainer {
      display: grid;
      grid:
        'album category' min-content
        'a-underline c-underline' 2px;
        / min-content min-content;
      grid-column-gap: 30px;
      .album {
        grid-area: album;
      }
      .category {
        grid-area: category;
      }
      .a-underline {
      grid-area: a-underline;
      }
      
      .c-underline {
      grid-area: c-underline;
      }
    }
  }
  .underline {
  width: 13px;
  background-color: ${theme.colors.slateGreyTitleText};
  height: 2px;
  }
  
  .search-line {
  display: none;
  grid-area: search-line;
  }
  
  .cross-icon {
  display: none;
  grid-area: search-line;
  }
  
  .menu-icon {
  display: none;
  grid-area: menu-icon;
  text-align: center;
  color: ${theme.colors.cloudyBlueText};
  }
  
  .search-icon {
  display: none;
  grid-area: search-icon;
  text-align: center;
  color: ${theme.colors.cloudyBlueText};
  }
  
  .mobile-search {
    display: none;
    padding-left: 5px;
    padding-right: 5px;
    
  }
  
  @media (max-width: 900px) {
  grid:
    'title' min-content
    'search' min-content
    'icons' min-content
     'switcher' min-content
    / 1fr;
  grid-gap: 40px;
  .title {
  text-align: center;
  }
  .search {
    grid-area: search;
    display: flex;
    justify-content: center;
  }
  .icons {
    justify-content: center;
   }
  
  }
  
  @media (${(props) => props.theme.mediaMobile}) {
  grid:
    'menu-icon title search-icon' min-content
    '. icons .' min-content
    / 45px auto 45px;
   grid-gap: 0px; 
   grid-row-gap: 10px;
    .title {
    text-align: center;
    font-size: 20px;
    height: 40px;
    align-items: center;
    }
    .search {
    display: none;
    }
    .switcher {
    display: none;
    }   
    
    .mobile-first-line {
    display: block;
    grid-area: mobileFirstLine;
    } 
    
    .search-line {
    display: block;
    grid-area: search-line;
  }
  
  .cross-icon {
    display: block;
    grid-area: cross-icon;
  }
 .menu-icon {
    display: flex;
    grid-area: menu-icon;
    align-items: center;
    justify-content: flex-end;
  }
  
  .search-icon {
    display: flex;
    grid-area: search-icon;
    align-items: center;
  }
  .mobile-search {
    display: flex;
    justify-content: center;
  }
}
  
  
`;

export default Header;
