import React, { FC } from 'react';
import '../../_styled/GlobalFonts';

type IconName =
  | 'icons-work-department'
  | 'email'
  | 'cross'
  | 'filter-small'
  | 'left'
  | 'search'
  | 'reward-platinum'
  | 'reward-gold'
  | 'reward-silver'
  | 'reward-bronze'
  | 'menu';

const Icon: FC<{
  name: IconName;
  onClick?: () => void;
  className?: string;
  lineHeight?: number;
}> = ({ name, onClick, lineHeight }) => {
  return (
    <i
      onClick={() => onClick && onClick()}
      className={`badges-` + name}
      style={{
        lineHeight,
      }}
    />
  );
};

export default Icon;
