import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { appActions, authActions } from '../../../_actions';
import styled from 'styled-components';
import { StyledFC, Link } from '../../_shared/types';
import { AppStateType } from '../../../_reducers';

const SideBarMobile_: StyledFC<{
  //isShow: boolean;
  links: Link[];
}> = ({ className, /*isShow,*/ links }) => {
  const isShow = useSelector(
    (state: AppStateType) => state.app.isShowMobileSidebar
  );

  const dispatch = useDispatch();
  const setIsShowMobileSidebar = useCallback(
    (val) => dispatch(appActions.setIsShowMobileSidebar(val)),
    [dispatch]
  );
  const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);

  const renderLinks = (links: Link[]) => {
    return links.map((link, idx) => {
      return (
        <div className="row" key={link.index}>
          <NavLink
            onClick={() => setIsShowMobileSidebar(false)}
            className={`col-12 d-flex align-items-center ${
              link.url === '/nope' ? 'cherta' : ''
            }`}
            activeClassName="active"
            to={link.url}
          >
            {link.url === '/nope' ? (
              <div className="cherta inner d-flex justify-content-center align-items-center">
                <span></span>
              </div>
            ) : (
              <Fragment>
                <div className="icon-wrapper">
                  <i className="badges badges-badges" />
                </div>
                <div className="item-text">{link.label}</div>
              </Fragment>
            )}
          </NavLink>
        </div>
      );
    });
  };
  return (
    <aside
      className={`${className} d-sm-none flex-column ${
        isShow ? 'd-flex' : 'd-none'
      }`}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-12 d-flex align-items-center"
            onClick={() => setIsShowMobileSidebar(false)}
          >
            <i className="badges badges-cross" />
          </div>
        </div>
        {/**/}
        {renderLinks(links)}
        {/**/}
        <div className="row">
          <div className="col-12 d-flex align-items-center" onClick={logout}>
            <div className="icon-wrapper">
              <i className="badges badges-exit" />
            </div>
            <div className="item-text">Выйти</div>
          </div>
        </div>
      </div>
    </aside>
  );
};

const SideBarMobile = styled(SideBarMobile_)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 5;
  .cherta {
    width: 100%;
    pointer-events: none;
    cursor: not-allowed !important;
    span {
      height: 2px;
      width: 70%;
      background-color: #d4dee9;
    }
  }
  .row {
    height: 68px;
    .col-12 {
      color: ${({ theme }) => theme.colors.cloudyBlueText};

      .badges-cross {
        cursor: pointer;
        font-size: 17px;
      }

      &.active {
        background: #aaa2fc;
        color: ${({ theme }) => theme.colors.white};
      }

      .icon-wrapper {
        width: 48px;
        display: flex;
        align-items: center;

        .badges-badges {
          font-size: 24px;
        }

        .badges-exit {
          font-size: 24px;
        }
      }

      .item-text {
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
      }
    }
  }
`;

export default SideBarMobile;
