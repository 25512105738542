import React, { useState } from 'react';
import { mailExpr } from '../../_shared/utils';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toastsActions } from '../../../_actions';
import { AppStateType } from '../../../_reducers';
import styled from 'styled-components';
import { StyledFC } from '../../_shared/types';
import { useSignUpMutation } from './queries';

const SignUpForm_: StyledFC<{}> = ({ className }) => {
  const dispatch = useDispatch();
  const addToast = (toast: {
    text: string;
    className: string;
    delay: number;
  }) => dispatch(toastsActions.addToast(toast));
  const { error, loading } = useSelector(
    (state: AppStateType) => state.restore
  );

  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signUpHook] = useSignUpMutation();

  const onBlur = () => {
    setEmailTouched(true);
  };

  const validate = (email: string) => {
    return email.length === 0 || !mailExpr.test(email);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await signUpHook({
        variables: {
          email,
        },
      });
      addToast({
        text: `Пароль отправлен на почту ${email}`,
        className: 'bg-info text-ligh t',
        delay: 3000,
      });
    } catch (error) {
      addToast({
        text: `Пользователь с таким Email не найден.`,
        className: 'bg-danger text-light',
        delay: 3000,
      });
    }
    setIsSubmitted(true);
  };

  const errors = validate(email);

  if (!loading && !error && isSubmitted) {
    return <Redirect to={`signin`} />;
  }

  return (
    <form className={`auth-page-form ${className} w-100`} onSubmit={onSubmit}>
      <div
        className={`form-group w-100 email ${
          email.length > 0 ? ' hasLabel' : ''
        }`}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          onBlur={onBlur}
          type="email"
          className={`form-control w-100 ${
            errors && emailTouched ? ' is-invalid' : ''
          }`}
          placeholder="Email"
          value={email}
        />
      </div>

      <button type="submit" className="btn btn-primary w-100" disabled={errors}>
        Получить пароль
      </button>

      <div className="link-container d-flex justify-content-center w-100">
        <span>
          <Link to={'signin'} className="link">
            Войти в аккаунт
          </Link>
        </span>
      </div>
    </form>
  );
};

const SignUpForm = styled(SignUpForm_)`
  height: 276px;
  margin-top: 104px;
  button[type='submit'] {
    margin-top: 20px;
  }
  .hasLabel::before {
    font-size: 14px !important;
  }
`;

export default SignUpForm;
