import { authConstants } from '../_constants';

const initialState = {
  error: null,
  loading: true,
};
const restore = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case authConstants.RESTORE_REQUEST:
      return {
        error: null,
        loading: true,
      };

    case authConstants.RESTORE_SUCCESS:
      return {
        error: null,
        loading: false,
      };

    case authConstants.RESTORE_FAILURE:
      return {
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default restore;
