import React, { useEffect, useRef, useState } from 'react';
import AchievementCard from '../_shared/AchievementCard';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { appActions } from '../../../_actions';
import { useDispatch } from 'react-redux';
import { Achievement } from '../../../_graphql/schema';
import {
  GetEventsIdsForEmployeeQueryHookResult,
  useGetEmployeeLazyQuery,
  useGetMaybeAssignmentsQuery,
} from './queries';
import Loader from '../_shared/Loader';
import { AchievementPage, AchievementIdPage } from '../_shared/AchievementPage';
import styled from 'styled-components';
import {
  StyledFC,
  Weight,
  User,
  AchievementCategory,
  TaggedAchievement,
} from '../../_shared/types';

import Header from './Header';

const BadgesPageView_: StyledFC<{
  weights: Weight[];
  categories?: AchievementCategory;
  isMe: boolean;
  user?: User;
  hookResult: GetEventsIdsForEmployeeQueryHookResult;
}> = ({ className, weights, categories, isMe, user, hookResult }) => {
  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setIsShowMobileSidebar } = bindActionCreators(
    {
      setIsShowMobileSidebar: appActions.setIsShowMobileSidebar,
    },
    dispatch
  );
  const userID =
    match.params.id !== undefined
      ? +match.params.id
      : Number(user?.employee.id || 0);

  const [searchString, setSearchString] = useState('');
  const [isAlbum, setIsAlbum] = useState(true);
  const [imageMap, setImageMap] = useState<{
    [key: number]: string;
  }>();

  const { data, loading } = hookResult;

  const [getEmployee, { data: employeeData }] = useGetEmployeeLazyQuery();

  const { data: getAssignmentsData } = useGetMaybeAssignmentsQuery({
    variables: { id: userID },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    getEmployee({ variables: { id: userID } });
  }, [userID, getEmployee]);

  useEffect(() => {
    const eventsList = data?.employee.events.items;
    if (eventsList) {
      let imageMap: { [key: number]: string } = {};
      for (let event of eventsList) {
        if (event.__typename === 'MemoryEvent') {
          imageMap[event.assignment.achievement.id] = event.image;
        }
      }
      setImageMap(imageMap);
    }
  }, [data]);

  const newAchievements = getAssignmentsData?.employee.maybeAssignments;

  useEffect(() => {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchString]);

  const searchInputRef = useRef<HTMLInputElement>(null);

  if (loading || !newAchievements || !employeeData?.employee || !imageMap) {
    return <Loader />;
  }

  const achievementList: TaggedAchievement[] = newAchievements.map((a) => {
    if (a.__typename === 'Assignment') {
      return {
        ...a.achievement,
        assignmentImage: imageMap[a.achievement.id],
        tag: 'Assignment',
      };
    }
    if (a.__typename === 'Achievement') {
      return {
        ...a,
        assignmentImage: imageMap[a.id],
        tag: 'Achievement',
      };
    }
    return undefined;
  }) as TaggedAchievement[];

  const allCategories: AchievementCategory[] = achievementList
    .filter((a: Achievement, idx: number) =>
      idx !== 0
        ? a.category.name !== achievementList[idx - 1].category.name
        : true
    )
    .map((a: Achievement) => ({
      name: a.category.name,
      achievements: achievementList.filter(
        (elem: Achievement) => elem.category.name === a.category.name
      ),
    }));

  return (
    <div className={className}>
      <Header
        area="header"
        isAlbumActive={isAlbum}
        setAlbumActive={setIsAlbum}
        onSearchStringChange={setSearchString}
        searchString={searchString}
        awardsCount={{
          bronze: 1,
          silver: 2,
          gold: 10,
          platinum: 99,
        }}
        onClickMenu={() => setIsShowMobileSidebar(true)}
      />
      {isAlbum ? (
        <div className="badgesContainer">
          <div className="badgesSubContainer">
            {achievementList
              .filter((item) =>
                item.name.toLowerCase().includes(searchString.toLowerCase())
              )
              .map((item) => (
                <div key={item.id} className="oneBadge">
                  <AchievementCard
                    userId={userID}
                    achievement={item}
                    isMe={isMe}
                    achievements={achievementList}
                  />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="badgesContainer">
          {allCategories.map((category, i) => {
            return (
              <React.Fragment key={i}>
                <div
                  className={`category-title ${
                    category.achievements.filter((a: Achievement) =>
                      a.name.toLowerCase().includes(searchString.toLowerCase())
                    ).length === 0
                      ? 'd-none'
                      : 'd-flex'
                  }`}
                >
                  <div className="hr">
                    <div className="line" />
                  </div>
                  <div className="category-name">{category.name}</div>
                  <div className="hr">
                    <div className="line" />
                  </div>
                </div>

                <div className="badgesSubContainer">
                  {category.achievements.map((achievement: TaggedAchievement) =>
                    achievement.name
                      .toLowerCase()
                      .includes(searchString.toLowerCase()) ? (
                      <div key={achievement.id} className="oneBadge">
                        <AchievementCard
                          achievement={achievement}
                          achievements={allCategories
                            .map((c) => c.achievements.map((a) => a))
                            .flat()}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      <Switch>
        <Route
          exact
          path="/badges/:id"
          render={({ match }) => {
            const { id } = match.params;
            return (
              <AchievementPage
                id={id}
                achievements={achievementList}
                isMe={isMe}
                onClose={() => history.push('/badges')}
              />
            );
          }}
        />
        <Route
          path="/badges/:id/:userId"
          render={({ match }) => {
            const { id } = match.params;
            return (
              <AchievementIdPage
                id={id}
                achievements={achievementList}
                isMe={isMe}
                onClose={() => history.push('/badges')}
              />
            );
          }}
        />
        <Route
          path={`/search-players/${match.params.id}/:id`}
          render={({ match }) => {
            const { id } = match.params;
            return (
              <AchievementPage
                id={id}
                achievements={achievementList}
                isMe={isMe}
                onClose={() => history.push('/search-players')}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

const BadgesPageView = styled(BadgesPageView_)`
  display: grid;
  width: 100%;
  padding-top: 35px;
  grid:
    '. header .' min-content
    '. badges .' auto
    / 135px auto 135px;
  grid-row-gap: 40px;

  .badgesContainer {
    grid-area: badges;
    overflow-y: scroll;
    padding: 25px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .badgesSubContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-column-gap: 50px;
    grid-row-gap: 20px;
  }

  .oneBadge {
    width: 100%;
  }

  .category-title {
    margin-bottom: 50px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .hr {
      width: 100%;
      .line {
        width: 100%;
        height: 4px;
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.paleGrey};
      }
    }

    .category-name {
      font-size: 28px;
      font-weight: 400;
      line-height: 0.96;
      letter-spacing: normal;
      text-align: center;
      color: ${({ theme }) => theme.colors.cloudyBlueText};
      width: 100%;
    }
  }

  @media (${(props) => props.theme.mediaMobile}) {
    grid:
      'header' min-content
      'badges' auto
      / auto;

    .badgesContainer {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      padding-right: 45px;
      padding-left: 45px;
      grid-column-gap: 30px;
    }
  }
`;

export default BadgesPageView;
