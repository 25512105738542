export enum authConstants {
  FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST',
  FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS',
  FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE',

  LOGOUT = 'LOGOUT',

  FETCH_TOKEN_REQUEST = 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS',
  FETCH_TOKEN_FAILURE = 'FETCH_TOKEN_FAILURE',

  RESTORE_REQUEST = 'RESTORE_REQUEST',
  RESTORE_SUCCESS = 'RESTORE_SUCCESS',
  RESTORE_FAILURE = 'RESTORE_FAILURE',

  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}
