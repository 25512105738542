import * as Types from '../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetPlayersQueryVariables = {
  string: Types.Scalars['String'];
};


export type GetPlayersQuery = (
  { __typename?: 'Query' }
  & { team: (
    { __typename?: 'EmployeePage' }
    & Pick<Types.EmployeePage, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'email' | 'position'>
      & { subdivision: (
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'name'>
      ) }
    )> }
  ), subscriptions: (
    { __typename?: 'EmployeePage' }
    & Pick<Types.EmployeePage, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'email' | 'position'>
      & { subdivision: (
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'name'>
      ) }
    )> }
  ), other: (
    { __typename?: 'EmployeePage' }
    & Pick<Types.EmployeePage, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'email' | 'position'>
      & { subdivision: (
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'name'>
      ) }
    )> }
  ) }
);

export type SubscribeMutationVariables = {
  id: Types.Scalars['Int'];
};


export type SubscribeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'subscribe'>
);

export type UnsubscribeMutationVariables = {
  id: Types.Scalars['Int'];
};


export type UnsubscribeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'unsubscribe'>
);


export const GetPlayersDocument = gql`
    query getPlayers($string: String!) {
  team: employees(withMe: false, group: TEAM, name: $string) {
    total
    items {
      id
      name
      avatar
      email
      position
      subdivision {
        name
      }
    }
  }
  subscriptions: employees(withMe: false, group: SUBSCRIPTIONS, name: $string) {
    total
    items {
      id
      name
      avatar
      email
      position
      subdivision {
        name
      }
    }
  }
  other: employees(withMe: false, name: $string) {
    total
    items {
      id
      name
      avatar
      email
      position
      subdivision {
        name
      }
    }
  }
}
    `;

/**
 * __useGetPlayersQuery__
 *
 * To run a query within a React component, call `useGetPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersQuery({
 *   variables: {
 *      string: // value for 'string'
 *   },
 * });
 */
export function useGetPlayersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
      }
export function useGetPlayersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
        }
export type GetPlayersQueryHookResult = ReturnType<typeof useGetPlayersQuery>;
export type GetPlayersLazyQueryHookResult = ReturnType<typeof useGetPlayersLazyQuery>;
export type GetPlayersQueryResult = ApolloReactCommon.QueryResult<GetPlayersQuery, GetPlayersQueryVariables>;
export const SubscribeDocument = gql`
    mutation subscribe($id: Int!) {
  subscribe(employee: $id)
}
    `;

/**
 * __useSubscribeMutation__
 *
 * To run a mutation, you first call `useSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeMutation, { data, loading, error }] = useSubscribeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeMutation, SubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<SubscribeMutation, SubscribeMutationVariables>(SubscribeDocument, baseOptions);
      }
export type SubscribeMutationHookResult = ReturnType<typeof useSubscribeMutation>;
export type SubscribeMutationResult = ApolloReactCommon.MutationResult<SubscribeMutation>;
export type SubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeMutation, SubscribeMutationVariables>;
export const UnsubscribeDocument = gql`
    mutation unsubscribe($id: Int!) {
  unsubscribe(employee: $id)
}
    `;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, baseOptions);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = ApolloReactCommon.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;