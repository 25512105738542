import * as Types from '../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type AchievementPartInProfilePageFragment = (
  { __typename?: 'Achievement' }
  & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'message' | 'image' | 'isActive' | 'animation'>
  & { weight: (
    { __typename?: 'AchievementWeight' }
    & Pick<Types.AchievementWeight, 'name' | 'value'>
  ), color: (
    { __typename?: 'AchievementColor' }
    & Pick<Types.AchievementColor, 'name' | 'value'>
  ), category: (
    { __typename?: 'Category' }
    & Pick<Types.Category, 'name'>
  ) }
);

export type GetAssignmentsByUserIdQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetAssignmentsByUserIdQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'name'>
    & { assigned: (
      { __typename?: 'AssignmentPage' }
      & { items: Array<(
        { __typename?: 'Assignment' }
        & Pick<Types.Assignment, 'id' | 'createdAt' | 'image'>
        & { achievement: (
          { __typename?: 'Achievement' }
          & AchievementPartInProfilePageFragment
        ) }
      )> }
    ) }
  ) }
);

export type GetEventsForEmployeeQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetEventsForEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & { events: (
      { __typename?: 'EventPage' }
      & Pick<Types.EventPage, 'total'>
      & { items: Array<(
        { __typename: 'AssignmentEvent' }
        & Pick<Types.AssignmentEvent, 'id' | 'date'>
        & { assignment: (
          { __typename?: 'Assignment' }
          & { employee: (
            { __typename?: 'Employee' }
            & Pick<Types.Employee, 'id'>
          ), achievement: (
            { __typename?: 'Achievement' }
            & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'image'>
            & { weight: (
              { __typename?: 'AchievementWeight' }
              & Pick<Types.AchievementWeight, 'id' | 'name' | 'value'>
            ) }
          ) }
        ) }
      ) | (
        { __typename: 'MemoryEvent' }
        & Pick<Types.MemoryEvent, 'id' | 'date' | 'description' | 'image'>
        & { assignment: (
          { __typename?: 'Assignment' }
          & { employee: (
            { __typename?: 'Employee' }
            & Pick<Types.Employee, 'id'>
          ), achievement: (
            { __typename?: 'Achievement' }
            & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'image'>
            & { weight: (
              { __typename?: 'AchievementWeight' }
              & Pick<Types.AchievementWeight, 'id' | 'name' | 'value'>
            ) }
          ) }
        ) }
      )> }
    ) }
  ) }
);

export const AchievementPartInProfilePageFragmentDoc = gql`
    fragment AchievementPartInProfilePage on Achievement {
  id
  name
  description
  message
  weight {
    name
    value
  }
  image
  color {
    name
    value
  }
  isActive
  animation
  category {
    name
  }
}
    `;
export const GetAssignmentsByUserIdDocument = gql`
    query getAssignmentsByUserId($id: Int!) {
  employee(id: $id) {
    name
    assigned {
      items {
        id
        createdAt
        image
        achievement {
          ...AchievementPartInProfilePage
        }
      }
    }
  }
}
    ${AchievementPartInProfilePageFragmentDoc}`;

/**
 * __useGetAssignmentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentsByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>(GetAssignmentsByUserIdDocument, baseOptions);
      }
export function useGetAssignmentsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>(GetAssignmentsByUserIdDocument, baseOptions);
        }
export type GetAssignmentsByUserIdQueryHookResult = ReturnType<typeof useGetAssignmentsByUserIdQuery>;
export type GetAssignmentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentsByUserIdLazyQuery>;
export type GetAssignmentsByUserIdQueryResult = ApolloReactCommon.QueryResult<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>;
export const GetEventsForEmployeeDocument = gql`
    query getEventsForEmployee($id: Int!) {
  employee(id: $id) {
    events(take: 50) {
      total
      items {
        __typename
        ... on AssignmentEvent {
          id
          date
          assignment {
            employee {
              id
            }
            achievement {
              id
              name
              description
              image
              weight {
                id
                name
                value
              }
            }
          }
        }
        ... on MemoryEvent {
          id
          date
          description
          image
          assignment {
            employee {
              id
            }
            achievement {
              id
              name
              description
              image
              weight {
                id
                name
                value
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsForEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEventsForEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsForEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsForEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventsForEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEventsForEmployeeQuery, GetEventsForEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEventsForEmployeeQuery, GetEventsForEmployeeQueryVariables>(GetEventsForEmployeeDocument, baseOptions);
      }
export function useGetEventsForEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventsForEmployeeQuery, GetEventsForEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEventsForEmployeeQuery, GetEventsForEmployeeQueryVariables>(GetEventsForEmployeeDocument, baseOptions);
        }
export type GetEventsForEmployeeQueryHookResult = ReturnType<typeof useGetEventsForEmployeeQuery>;
export type GetEventsForEmployeeLazyQueryHookResult = ReturnType<typeof useGetEventsForEmployeeLazyQuery>;
export type GetEventsForEmployeeQueryResult = ApolloReactCommon.QueryResult<GetEventsForEmployeeQuery, GetEventsForEmployeeQueryVariables>;