import React from 'react';
import styled from 'styled-components';
import { StyledFC } from '../../_shared/types';

const Loader_: StyledFC = ({ className }) => {
  return (
    <div className={className}>
      <div className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="5"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  );
};

const Loader = styled(Loader_)`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3c3c3caf;
  z-index: 100;
  display: flex;

  .loader {
    position: relative;
    margin: 0 auto;
    width: 100px;
    transform: rotate(180deg);
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 1.2s ease-in-out infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: #b2adf7;
    animation: dash 1.2s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 37, 200;
      stroke-dashoffset: -13px;
    }
    50% {
      stroke-dasharray: 63, 200;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 37, 200;
      stroke-dashoffset: -13px;
    }
  }
`;

export default Loader;
