import React from 'react';
import { StyledFC } from '../../../_shared/types';
import styled from 'styled-components';
import { theme } from '../../../../_styled/theme';
import { gridArea } from '../../PlayerPage/dom';

type BtnItem = {
  title: string;
  onPress: () => void;
  active?: boolean;
  area?: string;
};

interface SwitcherInterface {
  items: BtnItem[];
}

const _Switcher: StyledFC<SwitcherInterface> = ({ items, className }) => {
  return (
    <div className={className}>
      {items.map((item, index, array) => {
        if (index === 0) {
          return (
            <div
              className={`item left ${item.active ? 'active' : ''}`}
              onClick={item.onPress}
              key={index}
            >
              {item.title}
            </div>
          );
        } else if (index === array.length - 1) {
          return (
            <div
              className={`item right ${item.active ? 'active' : ''}`}
              onClick={item.onPress}
              key={index}
            >
              {item.title}
            </div>
          );
        } else {
          return (
            <div
              className={`item  ${item.active ? 'active' : ''}`}
              onClick={item.onPress}
              key={index}
            >
              {item.title}
            </div>
          );
        }
      })}
    </div>
  );
};

//Flex usage is due to the dynamic number of buttons in the switch.
const Switcher = styled(_Switcher)`
  ${gridArea()};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .item {
    flex: 1;
    flex-grow: 1;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.buttonTriggerActive};
    font-size: 18px;
    border: 1px solid ${theme.colors.playerPageElementsColor};
    cursor: pointer;
  }
  .left {
    border-bottom-left-radius: 14.5px;
    border-top-left-radius: 14.5px;
  }
  .right {
    border-bottom-right-radius: 14.5px;
    border-top-right-radius: 14.5px;
  }
  .active {
    background-color: ${theme.colors.playerPageElementsColor};
    color: white !important;
  }
`;

export default Switcher;
