import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/Circe-ExtraBold.eot');
  src: url('/assets/fonts/Circe-ExtraBold.woff') format('woff'),
    url('/assets/fonts/Circe-ExtraBold.ttf') format('truetype'),
    url('/assets/fonts/Circe-ExtraBold.svg') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/Circe-Light.eot');
  src: url('/assets/fonts/Circe-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Circe-Light.woff2') format('woff2'),
    url('/assets/fonts/Circe-Light.woff') format('woff'),
    url('/assets/fonts/Circe-Light.ttf') format('truetype'),
    url('/assets/fonts/Circe-Light.svg#Circe-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/Circe-Bold.eot');
  src: url('/assets/fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Circe-Bold.woff2') format('woff2'),
    url('/assets/fonts/Circe-Bold.woff') format('woff'),
    url('/assets/fonts/Circe-Bold.ttf') format('truetype'),
    url('/assets/fonts/Circe-Bold.svg#Circe-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/Circe-Regular.eot');
  src: url('/assets/fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Circe-Regular.woff2') format('woff2'),
    url('/assets/fonts/Circe-Regular.woff') format('woff'),
    url('/assets/fonts/Circe-Regular.ttf') format('truetype'),
    url('/assets/fonts/Circe-Regular.svg#Circe-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/Circe-ExtraLight.eot');
  src: url('/assets/fonts/Circe-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/Circe-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/Circe-ExtraLight.woff') format('woff'),
    url('/assets/fonts/Circe-ExtraLight.ttf') format('truetype'),
    url('/assets/fonts/Circe-ExtraLight.svg#Circe-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/badges-icons/badges.eot?xrn75x');
  src: url('/assets/fonts/badges-icons/badges.eot?xrn75x#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/badges-icons/badges.ttf?xrn75x') format('truetype'),
    url('/assets/fonts/badges-icons/badges.woff?xrn75x') format('woff'),
    url('/assets/fonts/badges-icons/badges.svg?xrn75x#badges') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.badges-friends-hover:before {
  content: '\\e917';
}
.badges-chat:before {
  content: '\\e900';
}
.badges-email:before {
  content: '\\e901';
}
.badges-filter:before {
  content: '\\e902';
}
.badges-filter-small:before {
  content: '\\e903';
}
.badges-help:before {
  content: '\\e904';
}
.badges-icons-work-department:before {
  content: '\\e913';
}
.badges-phone:before {
  content: '\\e914';
}
.badges-skills:before {
  content: '\\e915';
}
.badges-skills-mobile:before {
  content: '\\e916';
}
.badges-back:before {
  content: '\\e905';
}
.badges-badges:before {
  content: '\\e906';
}
.badges-cross:before {
  content: '\\e907';
}
.badges-exit:before {
  content: '\\e90e';
}
.badges-leaderboard:before {
  content: '\\e90f';
}
.badges-left:before {
  content: '\\e910';
}
.badges-menu:before {
  content: '\\e911';
}
.badges-mobile-star:before {
  content: '\\e919';
}
.badges-pencil-grey:before {
  content: '\\e918';
}
.badges-plus:before {
  content: '\\e91a';
}
.badges-reward-bronze:before {
  content: '\\e912';
}
.badges-reward-gold:before {
  content: '\\e908';
}
.badges-reward-locked:before {
  content: '\\e909';
}
.badges-reward-platinum:before {
  content: '\\e90a';
}
.badges-reward-silver:before {
  content: '\\e90b';
}
.badges-right:before {
  content: '\\e90c';
}
.badges-search:before {
  content: '\\e90d';
}
`;
