import API from './axios-api';

const login = (email: string, password: string) => {
  return API.post('', {
    query: `
            mutation signIn($email: String!, $password: String!) {
                signIn(email: $email, password: $password) {
                    accessToken
                    refreshToken
                }
            }`,
    variables: { email, password },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signIn : null,
      errors,
    };
  });
};

const refreshToken = (refreshToken: string) => {
  return API.post('', {
    query: `
            mutation signRefresh($refreshToken: String!) {
                signRefresh(refreshToken: $refreshToken) {
                    accessToken
                    refreshToken
                }
            }`,
    variables: { refreshToken },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signRefresh : null,
      errors,
    };
  });
};

const restore = (email: string) => {
  return API.post('', {
    query: `
            mutation signRestore($email: String!) {
                signRestore(email: $email)
            }`,
    variables: { email },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signRestore : null,
      errors,
    };
  });
};

const logout = (refreshToken: string) => {
  return API.post('', {
    query: `
            mutation signOut($refreshToken: String!) {
                 signOut(refreshToken: $refreshToken)
            }`,
    variables: { refreshToken },
  });
};

export const authService = {
  logout,
  login,
  refreshToken,
  restore,
};
