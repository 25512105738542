import React, { FC, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import AchievementPage from './AchievementPage';
import { shortAchivment } from '../../../_shared/types';

const AchievementIdPage: FC<{
  id: number;
  achievements?: shortAchivment[];
  isMe: boolean;
  userId?: number;
  onClose?: () => void;
  scrollToMemory?: boolean;
}> = (props) => {
  const match = useRouteMatch<{ userId: string }>();
  const [isModalOpened, setIsModalOpened] = useState(true);
  const history = useHistory();

  const passing = {
    ...props,
    onClose: props.onClose
      ? props.onClose
      : () => {
          setIsModalOpened(false);
          history.push('/badges');
        },
  };

  return isModalOpened ? (
    <AchievementPage userIdFromUrl={!!match.params.userId} {...passing} />
  ) : null;
};

export default AchievementIdPage;
