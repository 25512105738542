import React from 'react';
import styled from 'styled-components';
import { theme } from '../../_styled/theme';
import Icon from './Icon';
import { StyledFC } from './types';

const _SearchInput: StyledFC<{
  onChange: (e: string) => void;
  value: string;
}> = ({ value, className, onChange }) => {
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const clearString = () => onChange('');

  return (
    <div
      className={className}
      style={{
        borderColor: value.length > 0 ? 'rgb(136,124,253)' : undefined,
      }}
    >
      <Icon name={'search'} lineHeight={2} />
      <input
        type="text"
        placeholder="Найти достижение"
        onChange={changeHandler}
        value={value}
      />
      {value.length > 0 && (
        <Icon name={'cross'} lineHeight={2.0} onClick={clearString} />
      )}
    </div>
  );
};

const SearchInput = styled(_SearchInput)`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: ${theme.colors.paleGrey};
  font-size: 18px;
  background-color: ${theme.colors.paleGrey};
  max-width: 300px;
  padding-left: 24px;
  padding-right: 24px;
  color: rgb(183, 191, 202);
  transition: border-color 0.25s;
  input {
    width: 80%;
    border-width: 0px;
    padding-left: 5px;
    background-color: transparent;
    margin-left: 10px;
  }

  input::placeholder {
    color: ${theme.colors.cloudyBlueText};
  }
`;

export default SearchInput;
