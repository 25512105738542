import API from './axios-api';

const getAchievements = (id: number) => {
  return API.post('', {
    query: `
            query {
                employee(id: ${id}) {
                name
                maybeAssignments {
                    __typename
                    ... on Achievement {
                        id
                        name
                        description
                        message
                        weight {
                            name
                            value
                        }
                        image
                        color {
                            name
                            value
                        }
                        isActive
                        animation
                        category {
                            name
                        }
                    }
                    ... on Assignment {
                        createdAt
                        assignmentImage: image
                        achievement {
                            id
                            name
                            description
                            message
                            weight {
                                name
                                value
                            }
                            image
                            color {
                                name
                                value
                            }
                            isActive
                            animation
                            category {
                                name
                            }
                        }
                    }
                }
                }
            }`,
    variables: { id },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.employee.maybeAssignments : null,
      errors,
    };
  });
};

const getWeights = () => {
  return API.post('', {
    query: `
            query {
                achievementWeights {
                    id
                    name
                    value
                }
            }
        `,
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.achievementWeights : null,
      errors,
    };
  });
};

const subscribe = (id: number) => {
  return API.post('', {
    query: `
            mutation {
                subscribe(employee: ${id})
            }`,
    variables: { id },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.subscribe : null,
      errors,
    };
  });
};
const unsubscribe = (id: number) => {
  return API.post('', {
    query: `
            mutation {
                unsubscribe(employee: ${id})
            }`,
    variables: { id },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.unsubscribe : null,
      errors,
    };
  });
};

const getPlayers = (name = '') => {
  return API.post('', {
    query: `
        query {
            team: employees(withMe: false, group: TEAM name: "${name}") {
              total
              items {
                id
                name
                avatar
                email
                position
                subdivision {
                  name
                }
              }
            }
            subscriptions: employees(withMe: false, group: SUBSCRIPTIONS name: "${name}") {
              total
              items {
                id
                name
                avatar
                email
                position
                subdivision {
                  name
                }
              }
            }
            other: employees(withMe: false, name: "${name}") {
              total
              items {
                id
                name
                avatar
                email
                position
                subdivision {
                  name
                }
              }
            }
        }`,
    variables: { name },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data : null,
      errors,
    };
  });
};

const getPlayer = (id: number) => {
  return API.post('', {
    query: `
            query {
                employee(id: ${id}) {
                    id
                    name
                    email
                    internalEmail
                    position
                    isActive
                    phone
                    avatar
                }
            }`,
    variables: { id },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.employee : null,
      errors,
    };
  });
};

const getEvents = (group: number) => {
  return API.post('', {
    query: `
    query {
      me {
        events(group: ${group}) {
          total
          items {
            ... on AssignmentEvent {
              id
              date
              assignment {
                employee {
                  name
                  avatar
                  assigned(skip: 0, take: 2, orderBy: createdAt, orderDir: DESC) {
                    total
                    items {
                      achievement {
                        name
                        image
                      }
    
                      createdAt
                    }
                  }
                }
                achievement {
                  id
                }
              }
            }
            ... on MemoryEvent {
              id
              date
              image # Не null на уровне типов, в отличии от assignment.image
              description
              assignment {
                employee {
                  name
                  avatar
                  assigned(skip: 0, take: 2, orderBy: createdAt, orderDir: DESC) {
                    total
                    items {
                      achievement {
                        name
                        image
                      }
                      createdAt
                    }
                  }
                }
                achievement {
                  id
                }
              }
            }
          }
        }
      }
    }
    `,
    variables: { group },
  }).then(({ data }) => {
    return {
      data: data ? data.data.me.events : null,
    };
  });
};

const getEventsForEmployee = (id: number) => {
  return API.post('', {
    query: `
      query {
        employee(id: ${id}) {
          events(take: 50) {
            total
            items {
              __typename
              ... on AssignmentEvent {
                id
                date
                assignment {
                  employee {
                    id
                  }
                  achievement {
                    id
                    name
                    description
                    message
                    weight {
                        name
                        value
                    }
                    image
                    color {
                        name
                        value
                    }
                    isActive
                    animation
                    category {
                        name
                    }
                  }
                }
              }
              ... on MemoryEvent {
                id
                date
                image
                assignment {
                  employee {
                    id
                  }
                  achievement {
                    id
                    name
                    description
                    message
                    weight {
                        name
                        value
                    }
                    image
                    color {
                        name
                        value
                    }
                    isActive
                    animation
                    category {
                        name
                    }
                  }
                }
              }
            }
          }
        }
      }`,
    variables: { id },
  }).then(({ data }) => {
    return {
      data: data ? data.data.employee.events.items : null,
    };
  });
};

const getEventsImagesForEmployee = (id: string) => {
  return API.post('', {
    query: `
      query {
        employee(id: ${id}) {
          events(take: 50) {
            items {
              __typename
              ... on MemoryEvent {
                image
                assignment {
                  achievement {
                    id
                  }
                }
              }
            }
          }
        }
      }`,
    variables: { id },
  }).then(({ data }) => {
    return {
      data: data ? data.data.employee.events.items : null,
    };
  });
};

export const clientService = {
  getAchievements,
  getWeights,
  getPlayers,
  subscribe,
  unsubscribe,
  getPlayer,
  getEvents,
  getEventsForEmployee,
  getEventsImagesForEmployee,
};
