import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Employee } from '../../../_graphql/schema';
import { theme } from '../../../_styled/theme';
import { StyledFC } from '../../_shared/types';
import { gridArea } from './dom';
import EventsForMe from './EventsForMe';

const TitleBoxInFeed = styled.div`
  display: grid;
  grid:
    'title . see-all' min-content
    / 2fr 1fr 1fr;
`;

const AchievementTitle = styled.div`
  ${gridArea()};
  font-weight: bold;
  color: #565c62;
  font-size: 28px;

  @media (${(props) => props.theme.mediaMobile}) {
    font-size: 16px;
  }
`;

const SeeAllLink = styled(Link)`
  color: ${theme.colors.cloudyBlueText};
  font-size: 16px;
  text-align: right;
  ${gridArea()};
  @media (${(props) => props.theme.mediaMobile}) {
    font-size: 12px;
    color: ${theme.colors.primary};
  }
`;

const AchievementContainer = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(4, minmax(10px, 120px));
  justify-content: space-between;
  overflow: hidden;
  padding: 25px;
`;

interface ProfileFeedInterface {
  isShowProfile: boolean;
  notMe: boolean;
  user: Employee;
  content?: JSX.Element[];
  area?: string;
}

const _Feed: StyledFC<ProfileFeedInterface> = ({
  isShowProfile,
  notMe,
  user,
  content,
  className,
}) => {
  return (
    <div className={className}>
      {isShowProfile ? (
        <div>
          <TitleBoxInFeed>
            <AchievementTitle area="title">Достижения</AchievementTitle>
            <SeeAllLink
              area="see-all"
              to={{
                pathname: notMe ? '/search-players/' + user.id : '/badges',
              }}
            >
              Смотреть все
            </SeeAllLink>
          </TitleBoxInFeed>
          <AchievementContainer>{content}</AchievementContainer>
        </div>
      ) : (
        <EventsForMe notMe={notMe} user={user} />
      )}
    </div>
  );
};

const Feed = styled(_Feed)`
  ${gridArea()};
`;

export default Feed;
