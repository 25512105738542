import { userConstants } from '../_constants';

const initialState = {
  error: null,
  loading: true,
};
const register = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case userConstants.REGISTER_USER_REQUEST:
      return {
        error: null,
        loading: true,
      };

    case userConstants.REGISTER_USER_SUCCESS:
      return {
        error: null,
        loading: false,
      };

    case userConstants.REGISTER_USER_FAILURE:
      return {
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default register;
