import store from '../store';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { authActions } from '../_actions';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

const refreshAuthLogic = (failedRequest: any) => {
  const { refreshToken } = store.getState().auth;
  if (refreshToken) {
    return authActions.refreshToken(refreshToken)(store.dispatch);
  } else {
    return Promise.reject(failedRequest);
  }
};

API.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (response?.data?.errors) {
      return Promise.reject({
        message: response?.data?.errors.map(
          (error: any) => error.message.message || error.message
        ),
      });
    }
    return response;
  },

  (error) => {
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(API, refreshAuthLogic, {
  skipWhileRefreshing: false,
});

export default API;
