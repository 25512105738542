import { applyMiddleware, createStore } from 'redux';
import reducer from './_reducers';
import thunk from 'redux-thunk';
import refreshTokenMiddleware from './_middleware/refresh-token';
import localStorageMiddleware from './_middleware/local-storage';

// import {createLogger} from "redux-logger";
// const loggerMiddleware = createLogger();

const store = createStore(
  reducer,
  applyMiddleware(
    thunk,
    localStorageMiddleware,
    refreshTokenMiddleware
    // loggerMiddleware
  )
);
export default store;
