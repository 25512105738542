import React from 'react';
import { StyledFC } from './types';
import styled from 'styled-components';

interface ButtonInterface {
  label: string;
  disabled?: boolean;
}

const _SubmitButtonComponent: StyledFC<ButtonInterface> = ({
  disabled = false,
  label,
  className,
}) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={className}
      style={{
        opacity: disabled ? 0.5 : undefined,
      }}
    >
      {label}
    </button>
  );
};

const SubmitButtonComponent = styled(_SubmitButtonComponent)`
  color: #ffffff;
  background-color: #5a4afc;
  border-color: #4f3dfc;
  width: 100%;
  opacity: 0.5;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border-radius: 25px;

  :hover {
    opacity: 1;
  }
`;

export default SubmitButtonComponent;
