export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

/** Подразделение банка */
export type Subdivision = {
  __typename?: 'Subdivision';
  id: Scalars['Int'];
  name: Scalars['String'];
  fullName: Scalars['String'];
  /** Родительское подразделение */
  parent?: Maybe<Subdivision>;
};

export type SubdivisionPage = {
  __typename?: 'SubdivisionPage';
  /** Элементы страницы пагинации */
  items: Array<Subdivision>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

/** Сотрудник банка */
export type Employee = {
  __typename?: 'Employee';
  id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  internalEmail: Scalars['String'];
  position: Scalars['String'];
  isActive: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  subdivision: Subdivision;
  /** Главное подразделение, к которому косвенно принадлежит сотрудник */
  rootSubdivisionName: Scalars['String'];
  /** (Не)полученные достижения сотрудника. Упорядочены по категорям */
  maybeAssignments: Array<MaybeAssignment>;
  /** Достижения полученные сотрудником */
  assigned: AssignmentPage;
  /** Достижения, которые может получить сотрудник */
  notAssigned: AchievementPage;
  /** События сотрудника */
  events: EventPage;
};


/** Сотрудник банка */
export type EmployeeAssignedArgs = {
  orderBy?: Maybe<AssignmentOrderBy>;
  withImage?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  orderDir?: Maybe<OrderDir>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  project?: Maybe<Scalars['Int']>;
};


/** Сотрудник банка */
export type EmployeeNotAssignedArgs = {
  orderBy?: Maybe<AchievementOrderBy>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  orderDir?: Maybe<OrderDir>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  project?: Maybe<Scalars['Int']>;
};


/** Сотрудник банка */
export type EmployeeEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** Достижение или присвоение достижения. См. [Inline Fragments](https://graphql.org/learn/queries/#inline-fragments) */
export type MaybeAssignment = Achievement | Assignment;

/** Поле для сортировки присвоений достижений */
export enum AssignmentOrderBy {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  Weight = 'weight',
  Category = 'category',
  CreatedAt = 'createdAt'
}

/** Направления сортировки */
export enum OrderDir {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Поле для сортировки достижений */
export enum AchievementOrderBy {
  Id = 'id',
  Name = 'name',
  Weight = 'weight',
  Category = 'category'
}

export type EmployeePage = {
  __typename?: 'EmployeePage';
  /** Элементы страницы пагинации */
  items: Array<Employee>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  isActive: Scalars['Boolean'];
  image: Scalars['String'];
  subdivisions: SubdivisionPage;
  /** Подразделения являющиеся прямо/косвенно дочерними для подразделений проекта (включая их самих). Доступны для использования в категориях достижений */
  availableSubdivisions: SubdivisionPage;
  categories: CategoryPage;
  /** Сотрудники являющиеся участниками проекта (игроки) */
  players: EmployeePage;
  /** Сотрудники являющиеся участники проекта (не игроки) */
  notPlayers: EmployeePage;
  /** Сотрудники являющиеся администраторами проекта */
  managers: EmployeePage;
  /** Сотрудники не являющиеся администраторами проекта */
  notManagers: EmployeePage;
  /** Достижения в рамках проекта */
  achievements: AchievementPage;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectSubdivisionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectAvailableSubdivisionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  orderBy?: Maybe<CategoryOrderBy>;
  orderDir?: Maybe<OrderDir>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectPlayersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectNotPlayersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectManagersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectNotManagersArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


/** Проект - корпоративная игра банка. Сотрудиники могут быть её игроками или менеджерами (администраторами проекта) */
export type ProjectAchievementsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  orderDir?: Maybe<OrderDir>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AchievementOrderBy>;
};

/** Поле для сортировки категорий */
export enum CategoryOrderBy {
  Id = 'id',
  Name = 'name',
  AchievementsCount = 'achievementsCount'
}

/** Команда (коллеги по подразделению) или Подписки */
export enum EmployeeGroup {
  Team = 'TEAM',
  Subscriptions = 'SUBSCRIPTIONS'
}

/** Поле для сортировки сотрудников */
export enum EmployeeOrderBy {
  Id = 'id',
  Name = 'name',
  Email = 'email',
  Position = 'position',
  Subdivision = 'subdivision',
  RootSubdivisionName = 'rootSubdivisionName'
}

export type ProjectPage = {
  __typename?: 'ProjectPage';
  /** Элементы страницы пагинации */
  items: Array<Project>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

export type PlayersOfSubdivisions = {
  __typename?: 'PlayersOfSubdivisions';
  subdivision: Subdivision;
  /** Кол-во сотрудников подразделения являющиеся игроками проекта */
  inProject: Scalars['Int'];
  /** Кол-во сотрудников подразделения */
  inSubdivision: Scalars['Int'];
};

/** Вес достижения */
export type AchievementWeight = {
  __typename?: 'AchievementWeight';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['Float'];
};

/** Цвет достижения */
export type AchievementColor = {
  __typename?: 'AchievementColor';
  id: Scalars['Float'];
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Достижение в рамках проекта */
export type Achievement = {
  __typename?: 'Achievement';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  /** Сообщение для пользователя при получении достижения */
  message: Scalars['String'];
  isActive: Scalars['Boolean'];
  image: Scalars['String'];
  animation?: Maybe<Scalars['String']>;
  weight: AchievementWeight;
  color: AchievementColor;
  category: Category;
  /** Присвоения достижения */
  assignments: AssignmentPage;
  /** Сотрудники доступные для присвоения данного достижения. Backend поиск, сортировка и пагинация */
  validEmployees: EmployeePage;
  /** Сотрудники достпные для присовения данного достижения. Загрузка для frontend поиска, фильтрации и пагинации */
  mapValidEmployees: MappedValidEmployees;
};


/** Достижение в рамках проекта */
export type AchievementAssignmentsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  orderDir?: Maybe<OrderDir>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AssignmentOrderBy>;
  withImage?: Maybe<Scalars['Boolean']>;
};


/** Достижение в рамках проекта */
export type AchievementValidEmployeesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


/** Достижение в рамках проекта */
export type AchievementMapValidEmployeesArgs = {
  include: Array<Scalars['Int']>;
};

export type AchievementPage = {
  __typename?: 'AchievementPage';
  /** Элементы страницы пагинации */
  items: Array<Achievement>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

export type MappedValidEmployees = {
  __typename?: 'MappedValidEmployees';
  valid: Array<Employee>;
  invalid: Array<Scalars['Int']>;
};

/** Категория достижений в рамках проекта */
export type Category = {
  __typename?: 'Category';
  id: Scalars['Int'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  project: Project;
  achievementsCount: Scalars['Int'];
  subdivisions: SubdivisionPage;
  availableSubdivisions: SubdivisionPage;
  /** TODO */
  countOfPlayers: Scalars['Int'];
  /** Достижения данной категории */
  achievements: AchievementPage;
};


/** Категория достижений в рамках проекта */
export type CategorySubdivisionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['Int']>>;
};


/** Категория достижений в рамках проекта */
export type CategoryAvailableSubdivisionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['Int']>>;
};


/** Категория достижений в рамках проекта */
export type CategoryAchievementsArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  orderDir?: Maybe<OrderDir>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<AchievementOrderBy>;
};

export type CategoryPage = {
  __typename?: 'CategoryPage';
  /** Элементы страницы пагинации */
  items: Array<Category>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

/** Присвоение достижения игроку (сотруднику банка) в рамках проекта */
export type Assignment = {
  __typename?: 'Assignment';
  /** Это ID именно присвоения, а не достижения. Не путайте! */
  id: Scalars['Int'];
  employee: Employee;
  achievement: Achievement;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};


export type AssignmentPage = {
  __typename?: 'AssignmentPage';
  /** Элементы страницы пагинации */
  items: Array<Assignment>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

export type AssignmentsCreated = {
  __typename?: 'AssignmentsCreated';
  /** Кол-во успешных присвоений */
  successCount: Scalars['Int'];
  /** ИД сотрудников/достижений (в зависимости от операции), для которых присвоение не допустимо. В случае с mode = EXCLUDE всегда пусто. */
  failed: Array<Scalars['Int']>;
};

export type EventPage = {
  __typename?: 'EventPage';
  /** Элементы страницы пагинации */
  items: Array<Event>;
  /** Все элементов (без учёта пагинации) */
  total: Scalars['Int'];
};

/** Событие получаемое подписчиком */
export type Event = AssignmentEvent | MemoryEvent;

export type AssignmentEvent = {
  __typename?: 'AssignmentEvent';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  /** Событие о получении достижения */
  assignment: Assignment;
};

export type MemoryEvent = {
  __typename?: 'MemoryEvent';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  /** Событие о добавлении воспоминания */
  assignment: Assignment;
  /** Картинка - всегда не null */
  image: Scalars['String'];
  description: Scalars['String'];
};

/** Профиль текущего пользователя */
export type Me = {
  __typename?: 'Me';
  email: Scalars['String'];
  /** Информация о пользователе-сотруднике. Если null, значит это супер-админ */
  employee?: Maybe<Employee>;
  /** События моих коллег */
  events: EventPage;
};


/** Профиль текущего пользователя */
export type MeEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  group: EmployeeGroup;
};

export type SignedIn = {
  __typename?: 'SignedIn';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  me: Me;
};

export type Query = {
  __typename?: 'Query';
  employee: Employee;
  employees: EmployeePage;
  category: Category;
  /** Проекты доступные их менеджеру */
  projects: ProjectPage;
  /** Проект доступный его менеджеру */
  project: Project;
  subdivision: Subdivision;
  /** Подразделения банка */
  subdivisions: SubdivisionPage;
  assignment: Assignment;
  achievement: Achievement;
  achievementWeights: Array<AchievementWeight>;
  achievementColors: Array<AchievementColor>;
  /** Информация об авторизованном пользователе */
  me: Me;
};


export type QueryEmployeeArgs = {
  id: Scalars['Int'];
};


export type QueryEmployeesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  group?: Maybe<EmployeeGroup>;
  withMe?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  rootSubdivisionName?: Maybe<Scalars['String']>;
  orderBy?: Maybe<EmployeeOrderBy>;
  orderDir?: Maybe<OrderDir>;
  withoutAchievements?: Maybe<Array<Scalars['Int']>>;
};


export type QueryCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryProjectsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryProjectArgs = {
  id: Scalars['Int'];
};


export type QuerySubdivisionArgs = {
  id: Scalars['Int'];
};


export type QuerySubdivisionsArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['Int']>>;
};


export type QueryAssignmentArgs = {
  employee: Scalars['Int'];
  achievement: Scalars['Int'];
};


export type QueryAchievementArgs = {
  id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCategory: Category;
  updateCategory: Category;
  deleteCategory: Scalars['Boolean'];
  createProject: Project;
  updateProject: Project;
  deleteProject: Scalars['Boolean'];
  /** Добавление сотрудников в проект в качестве игроков */
  addPlayers: Scalars['Boolean'];
  /** Добавление сотрудника в проект в качестве администратора */
  addManagers: Scalars['Boolean'];
  /** Удаление администраторов проекта */
  removeManager: Scalars['Boolean'];
  /** Присвоить достижение всем допустимым сотрудникам или включая/исключая заданные из допустимых. В режиме ALL параметр employees должен отсутствовать */
  assignAchievementTo: AssignmentsCreated;
  /** Присвоить сотруднику все допустимые достижения или включая/исключая заданные из допустимых. В режиме ALL параметр achievements должен отсутствовать */
  assignToEmployee: AssignmentsCreated;
  /** Отнять достижения у каждого из сотрудников. Возвращает кол-во успешно отнятых достижений */
  deleteAssignments: Scalars['Int'];
  updateAssignment: Assignment;
  /** Эквивалентно signRestore */
  signUp: Scalars['Boolean'];
  /** Генерируем новый пароль и отправляем на почту */
  signRestore: Scalars['Boolean'];
  signIn: SignedIn;
  /** В заголовке запроса должен присутствовать текущий AccessToken, в теле запроса передать RefreshToken. При успешной аутентификации из базы удалится переданый RefreshToken. */
  signOut: Scalars['Boolean'];
  /** В заголовке запроса должен присутствовать текущий AccessToken, в теле запроса передать RefreshToken. При успешной аутентифифкации возвращает новую пару AccessToken+RefreshToken и информацию о пользователе. */
  signRefresh: SignedIn;
  createAchievement: Achievement;
  updateAchievement: Achievement;
  deleteAchievement: Scalars['Boolean'];
  subscribe: Scalars['Boolean'];
  unsubscribe: Scalars['Boolean'];
};


export type MutationCreateCategoryArgs = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  project: Scalars['Int'];
  subdivisions: Array<Scalars['Int']>;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  subdivisions?: Maybe<Array<Scalars['Int']>>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  subdivisions: Array<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  subdivisions?: Maybe<Array<Scalars['Int']>>;
  image?: Maybe<Scalars['String']>;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['Int'];
};


export type MutationAddPlayersArgs = {
  project: Scalars['Int'];
  employees: Array<Scalars['Int']>;
};


export type MutationAddManagersArgs = {
  project: Scalars['Int'];
  employees: Array<Scalars['Int']>;
};


export type MutationRemoveManagerArgs = {
  project: Scalars['Int'];
  employee: Scalars['Int'];
};


export type MutationAssignAchievementToArgs = {
  achievement: Scalars['Int'];
  employees?: Maybe<Array<Scalars['Int']>>;
  mode: ElementMode;
};


export type MutationAssignToEmployeeArgs = {
  employee: Scalars['Int'];
  achievements?: Maybe<Array<Scalars['Int']>>;
  mode: ElementMode;
};


export type MutationDeleteAssignmentsArgs = {
  employees: Array<Scalars['Int']>;
  achievements: Array<Scalars['Int']>;
};


export type MutationUpdateAssignmentArgs = {
  employee: Scalars['Int'];
  achievement: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
};


export type MutationSignRestoreArgs = {
  email: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignOutArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSignRefreshArgs = {
  refreshToken: Scalars['String'];
};


export type MutationCreateAchievementArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  message: Scalars['String'];
  isActive: Scalars['Boolean'];
  weight: Scalars['Int'];
  color: Scalars['Int'];
  category: Scalars['Int'];
  image: Scalars['String'];
  animation?: Maybe<Scalars['String']>;
};


export type MutationUpdateAchievementArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  animation?: Maybe<Scalars['String']>;
};


export type MutationDeleteAchievementArgs = {
  id: Scalars['Int'];
};


export type MutationSubscribeArgs = {
  employee: Scalars['Int'];
};


export type MutationUnsubscribeArgs = {
  employee: Scalars['Int'];
};

/** Режим включения/исключения элементов */
export enum ElementMode {
  IncludeAll = 'INCLUDE_ALL',
  Include = 'INCLUDE',
  Exclude = 'EXCLUDE'
}


      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "MaybeAssignment",
        "possibleTypes": [
          {
            "name": "Achievement"
          },
          {
            "name": "Assignment"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Event",
        "possibleTypes": [
          {
            "name": "AssignmentEvent"
          },
          {
            "name": "MemoryEvent"
          }
        ]
      }
    ]
  }
};
      export default result;
    