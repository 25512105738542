import React, { useCallback, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthPage from './AuthPage';
import DashboardPage from './DashboardPage';
import ProtectedRoute from './ProtectedRoute';
import ToastList from './ToastList';
import { appActions } from '../_actions';
import { useDispatch } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import client from '../_graphql/client-apollo';
import styled from 'styled-components';
import { StyledFC } from './_shared/types';

const App_: StyledFC<{}> = ({ className }) => {
  const dispatch = useDispatch();
  const setIsMobile = useCallback(
    (val) => dispatch(appActions.setIsMobile(val)),
    [dispatch]
  );

  useEffect(() => {
    const onResize = (event: any) => {
      if (event !== null) setIsMobile(event.target.innerWidth <= 576);
    };

    setIsMobile(window.innerWidth <= 576);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [dispatch, setIsMobile]);

  return (
    <div className={className}>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/auth" component={AuthPage} />
          <ProtectedRoute path="/" component={DashboardPage} />
        </Switch>
      </ApolloProvider>
      <ToastList />
    </div>
  );
};

const App = styled(App_)`
  height: 100%;
  width: 100%;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

export default App;
