import React from 'react';
import styled from 'styled-components';
import { StyledFC } from '../../../_shared/types';
import { DefaultTheme } from 'styled-components/macro';

const HeaderMobile_: StyledFC<{
  classSpec?: string;
  containerWrapper: StyledFC;
  searchString: string;
  searchPlayer?: boolean;
  isShowMobileSearch: boolean;
  isShowColleague?: boolean;
  onSearchStringChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsShowMobileSidebar: (value: boolean) => void;
  setIsShowMobileSearch: (value: boolean) => void;
  setIsShowColleague?: (value: boolean) => void;
}> = ({
  className,
  classSpec = '',
  containerWrapper: Container,
  searchString,
  searchPlayer,
  isShowColleague,
  onSearchStringChange,
  setIsShowMobileSidebar,
  setIsShowMobileSearch,
  isShowMobileSearch,
  setIsShowColleague = () => null,
}) => {
  return (
    <header className={`${className} ${classSpec}`}>
      <Container>
        <div className="row d-flex d-sm-none align-items-center h-100">
          <div className="col-12 d-flex align-items-center">
            {searchPlayer && !isShowMobileSearch ? (
              isShowColleague && !isShowMobileSearch ? (
                <>
                  <i
                    className="badges badges-back"
                    onClick={() => setIsShowColleague(false)}
                  />
                  <div className="title flex-grow-1">Коллеги</div>
                  <i
                    className="badges badges-search pointer"
                    onClick={() => setIsShowMobileSearch(true)}
                  />
                </>
              ) : (
                <>
                  <i
                    className="badges badges-menu pointer"
                    onClick={() => setIsShowMobileSidebar(true)}
                  />
                  <div className="title flex-grow-1">События коллег</div>
                  <i
                    className="badges badges-icons-work-department"
                    onClick={() => setIsShowColleague(true)}
                  />
                </>
              )
            ) : !isShowMobileSearch ? (
              <>
                <i
                  className="badges badges-menu pointer"
                  onClick={() => setIsShowMobileSidebar(true)}
                />
                <div className="title flex-grow-1">Достижения</div>
                <i
                  className="badges badges-search pointer"
                  onClick={() => setIsShowMobileSearch(true)}
                />
              </>
            ) : (
              <>
                <div className="form-group has-search">
                  <i className="badges badges-search" />
                  <input
                    onChange={onSearchStringChange}
                    className="form-control"
                    placeholder={
                      isShowColleague ? 'Найти коллег' : 'Найти достижение'
                    }
                    value={searchString}
                    type="text"
                  />
                </div>
                <i
                  className="badges badges-cross pointer"
                  onClick={() => setIsShowMobileSearch(false)}
                />
              </>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

const HeaderMobile = styled(HeaderMobile_)`
  width: 100%;
  height: ${({ theme }: { theme: DefaultTheme }) =>
    theme.headerHeightMobile} !important;
  i {
    font-size: 19px;
    color: ${({ theme }: { theme: DefaultTheme }) =>
      theme.colors.cloudyBlueText};
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${({ theme }: { theme: DefaultTheme }) =>
      theme.colors.cloudyBlueText};
  }

  .form-group {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
    margin-bottom: 0;

    .badges-search {
      position: absolute;
      left: 20px;
      top: 9px;
    }

    input {
      padding-left: 59px;
    }

    .form-control {
      padding: 0 48px;
      height: 40px;
      font-size: 18px;
    }
  }

  .badges-cross {
    margin-left: 25px;
  }
`;

export default HeaderMobile;
