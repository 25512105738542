import {
  clientConstants,
  authConstants,
  userConstants,
  appConstants,
  toastsConstants,
} from './';

export * from './client-constants';
export * from './auth-constants';
export * from './user-constants';
export * from './app-constants';
export * from './toasts-constants';

const AllConstants = {
  ...clientConstants,
  ...authConstants,
  ...userConstants,
  ...appConstants,
  ...toastsConstants,
};
type AllConstants = typeof AllConstants;
export default AllConstants;
