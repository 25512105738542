import React, { useState } from 'react';
import styled from 'styled-components';
import { AchievementWeight, Employee } from '../../../_graphql/schema';
import { CustomAssignmentOfPlayerPage, StyledFC } from '../../_shared/types';
import AssignmentCard from '../_shared/AssignementCard';
import moment from 'moment';
import Feed from './Feed';
import { useGetAssignmentsByUserIdQuery } from './queries';
import ProfilePageHeader from './Header';
import Switcher from '../_shared/RoundedSwitcher';

interface PlayerPageInterface {
  user: Employee;
  weights: AchievementWeight[];
  achievements?: CustomAssignmentOfPlayerPage[];
  notMe: boolean;
  subscribed: boolean;
  onSubscribe?: (id: number, subscribed: boolean) => void;
  onClosePlayer?: (val: boolean) => void;
}

const _PlayerPage: StyledFC<PlayerPageInterface> = ({
  user,
  achievements,
  notMe,
  subscribed,
  onSubscribe,
  onClosePlayer,
  className,
}) => {
  const [isShowProfile, setShowProfile] = useState(true);
  const { data: allPlayerAssignmentNew } = useGetAssignmentsByUserIdQuery({
    variables: { id: user?.id },
  });

  const assignments = allPlayerAssignmentNew?.employee.assigned.items;

  const getAllAchivements = (
    achievements: CustomAssignmentOfPlayerPage[] | undefined
  ) => {
    if (!achievements) return [];
    return achievements.map((a) => a.achievement);
  };

  const content = assignments
    ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
    .slice(0, 4)
    .map((item, index) => (
      <div key={index}>
        <AssignmentCard
          userId={user?.id}
          assignment={item}
          achievements={getAllAchivements(achievements)}
          isMe={!notMe}
        />
      </div>
    ));

  const configurationOfSwitcher = [
    {
      title: 'Профиль',
      onPress: () => setShowProfile(true),
      active: isShowProfile,
    },
    {
      title: 'Уведомления',
      onPress: () => setShowProfile(false),
      active: !isShowProfile,
    },
  ];

  return (
    <div className={className}>
      <ProfilePageHeader
        area="header"
        user={user}
        onClosePlayer={onClosePlayer}
        subscribed={subscribed}
        onSubscribe={onSubscribe}
      />
      <Switcher items={configurationOfSwitcher} area="switch" />
      <Feed
        isShowProfile={isShowProfile}
        notMe={notMe}
        user={user}
        content={content}
        area="feed"
      />
    </div>
  );
};

const PlayerPage = styled(_PlayerPage)`
  max-width: 620px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid:
    'header   header  header' min-content
    '.        switch  .     ' min-content
    'feed     feed    feed' min-content
    / 120px auto 120px;
  grid-row-gap: 40px;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (${(props) => props.theme.mediaMobile}) {
    grid:
      'header' min-content
      'switch' min-content
      'feed' min-content
      / 100%;
    grid-row-gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export default PlayerPage;
