import { clientConstants } from '../_constants';
import { combineReducers } from 'redux';
import {
  MaybeAssignment,
  AchievementWeight,
  Employee,
} from '../_graphql/schema';

const achievementList = (
  state: any = {},
  action: { type: string; payload: MaybeAssignment[] }
) => {
  switch (action.type) {
    case clientConstants.ACHIEVEMENT_LIST_REQUEST:
      return {
        loading: true,
        error: null,
      };
    case clientConstants.ACHIEVEMENT_LIST_SUCCESS:
      return {
        achievements: action.payload,
        loading: false,
        error: null,
      };
    case clientConstants.ACHIEVEMENT_LIST_FAILURE:
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
const weightList = (
  state: any = {},
  action: { type: string; payload: AchievementWeight[] }
) => {
  switch (action.type) {
    case clientConstants.WEIGHT_LIST_REQUEST:
      return {
        loading: true,
        error: null,
      };
    case clientConstants.WEIGHT_LIST_SUCCESS:
      return {
        weights: action.payload,
        loading: false,
        error: null,
      };
    case clientConstants.WEIGHT_LIST_FAILURE:
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
const playersList = (
  state = {},
  action: {
    type: string;
    payload: {
      team: Employee[];
      subscriptions: Employee[];
      other: Employee[];
    };
  }
) => {
  switch (action.type) {
    case clientConstants.PLAYERS_LIST_REQUEST:
      return {
        loading: true,
        error: null,
      };
    case clientConstants.PLAYERS_LIST_SUCCESS:
      return {
        players: action.payload,
        loading: false,
        error: null,
      };
    case clientConstants.PLAYERS_LIST_FAILURE:
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
const client = combineReducers({ weightList, achievementList, playersList });
export default client;
