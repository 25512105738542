import React, { useCallback, useEffect, useState } from 'react';
import BadgesPage from './BadgesPage';
import LeaderboardPage from './LeaderboardPage';
import PlayerPage from './PlayerPage';
import SearchPlayersPage from './SearchPlayersPage';
import SideBar from './SideBar';
import SideBarMobile from './SideBarMobile';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions, userActions } from '../../_actions';
import styled, { DefaultTheme } from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  useGetMyEmployeeIdQuery,
  useGetAssignmentsByUserIdLazyQuery,
} from './_shared/queries';
import { AppStateType } from '../../_reducers';
import { StyledFC } from '../_shared/types';
import { bp } from '@bootstrap-styled/css-mixins';

const linksArray = [
  {
    label: 'Игрок',
    url: '/player',
    icon: 'player',
    index: 0,
  },
  {
    label: 'Достижения',
    url: '/badges',
    icon: 'badges-badges',
    index: 1,
  },
  {
    label: 'Черта',
    url: '/nope',
    icon: '',
    index: 2,
  },
  {
    label: 'Поиск игроков',
    url: '/search-players',
    icon: 'badges-friends-hover',
    index: 3,
  },
];

const DashboardComponent: StyledFC = ({ className }) => {
  const [isDataLoad, setLoad] = useState(false);

  const user = useSelector((state: AppStateType) => state.user['data']);
  const weights = useSelector(
    (state: AppStateType) => state.client.weightList?.weights
  );

  const achievementList = useSelector(
    (state: AppStateType) => state.client.achievementList
  );

  const dispatch = useDispatch();
  const getWeights = useCallback(() => dispatch(clientActions.getWeights()), [
    dispatch,
  ]);

  const getPlayers = useCallback(() => dispatch(clientActions.getPlayers()), [
    dispatch,
  ]);

  const getUser = useCallback(() => dispatch(userActions.getUser()), [
    dispatch,
  ]);

  const { data: myEmployeeIdData } = useGetMyEmployeeIdQuery();

  const [
    getAssignment,
    { data: playerAssignments },
  ] = useGetAssignmentsByUserIdLazyQuery();

  const myEmployeeId = myEmployeeIdData?.me.employee?.id;

  const newAssignments = playerAssignments?.employee.assigned.items;

  const getAllData = useCallback(() => {
    getPlayers();
    getUser();
    getWeights();
    myEmployeeId &&
      getAssignment({
        variables: {
          id: myEmployeeId,
        },
      });
  }, [getAssignment, getPlayers, getUser, getWeights, myEmployeeId]);

  useEffect(() => {
    getAllData();
    setLoad(true);
  }, [getAllData, isDataLoad, myEmployeeId]);

  return user ? (
    <div className={`${className} container-fluid h-100`}>
      <div className="row flex-xl-nowrap h-100">
        <SideBar links={linksArray} user={user} />
        <SideBarMobile links={linksArray} />
        <div className="content-container d-flex justify-content-center h-100">
          <Switch>
            <Route
              path="/player"
              render={() => (
                <PlayerPage
                  user={user['employee']}
                  weights={weights}
                  achievements={newAssignments}
                  notMe={false}
                  subscribed={false}
                />
              )}
            />
            <Route
              path="/badges"
              render={() =>
                user?.['employee']?.['id'] ? (
                  <BadgesPage
                    weights={weights}
                    categories={achievementList.categories}
                    isMe={true}
                    user={user}
                  />
                ) : (
                  <></>
                )
              }
            />
            <Route path="/leaderboard" exact component={LeaderboardPage} />
            <Route
              path="/search-players/:id"
              render={() => <BadgesPage weights={weights} isMe={false} />}
            />
            <Route
              path="/search-players"
              exact
              render={() => <SearchPlayersPage />}
            />
            <Route render={() => <Redirect to="/badges" />} />
          </Switch>
        </div>
      </div>
    </div>
  ) : (
    <div>loading...</div>
  );
};

const xsMedia = (theme: DefaultTheme) =>
  bp.down(
    `xs`,
    undefined,
    `
    .content-container {
      width: 100%;
    }
    `
  );

export default styled(DashboardComponent)`
  .content-container {
    width: calc(100% - ${({ theme }) => theme.sidebarWidth});
  }

  ${({ theme }) => xsMedia(theme)}
`;
