import { authConstants } from '../_constants';
import jwt from 'jsonwebtoken';
import { authService } from '../_services';
import store from '../store';

let accessToken = '';
let refreshToken = '';
let tokenExpiredDate: Date;
let tokenExpiredLimit: number;

setInterval(() => {
  if (!store.getState().auth.accessToken) return;
  if (isTokenExpired()) {
    getNewTokens();
  }
}, 5000);

const isTokenExpired = () => {
  if (!accessToken) return false;
  const timeLeft = getDifferenceBetweenDates(
    new Date(),
    new Date(tokenExpiredDate)
  );
  const limit = Math.floor(tokenExpiredLimit * 0.8);
  return timeLeft < limit;
};

const getDifferenceBetweenDates = (startDate: Date, endDate: Date) => {
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
};

const getNewTokens = () => {
  authService
    .refreshToken(refreshToken)
    .then((response) => {
      const newAccessToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;
      store.dispatch(success(newAccessToken, newRefreshToken));
    })
    .catch((err) => {
      console.log('Error while getting new token', err);
      window.location.href = '/';
    });
};

const refreshTokenMiddleware = () => (
  next: (action: { type: authConstants; payload: any }) => void
) => (action: { type: authConstants; payload: any }) => {
  const payload = getPayload(action);
  if (payload.accessToken) {
    accessToken = payload.accessToken;
    refreshToken = payload.refreshToken;
    setTokenExpiredDate(accessToken);
  }
  next(action);
};

const getPayload = (action: { type: authConstants; payload: any }) => {
  if (
    [
      authConstants.FETCH_LOGIN_SUCCESS,
      authConstants.FETCH_TOKEN_SUCCESS,
    ].includes(action.type)
  ) {
    return action.payload;
  } else {
    return {
      accessToken: store.getState().auth.accessToken,
      refreshToken: store.getState().auth.refreshToken,
    };
  }
};

const setTokenExpiredDate = (token: string) => {
  const decodeData = jwt.decode(token) as { exp: number };
  tokenExpiredDate = new Date(decodeData.exp);
  tokenExpiredLimit = getDifferenceBetweenDates(new Date(), tokenExpiredDate);
};

function success(accessToken: string, refreshToken: string) {
  return {
    type: authConstants.FETCH_TOKEN_SUCCESS,
    payload: { accessToken, refreshToken },
  };
}

export default refreshTokenMiddleware;
