import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components';
import { Provider } from 'react-redux';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { theme } from './_styled/theme';
import GlobalFonts from './_styled/GlobalFonts';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalFonts />
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
