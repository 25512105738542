import { DefaultTheme } from 'styled-components/macro';

const theme: DefaultTheme = {
  baseTheme: 'Bootstrap',
  skinName: 'badges',
  swatchName: 'Bootstrap',
  borderRadius: '25px',
  gridGutterWidth: '40px',
  btnDisabledOpacity: '0.5',
  headerHeight: '155px',
  headerHeightMobile: '56px',
  sidebarWidth: '100px',
  containerMaxWidths: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1280px',
  },
  mediaMobile: 'max-width: 576px',
  colors: {
    white: '#ffffff',
    paleGrey: '#f5f7fd',
    paleGreyTwo: '#eceff9',
    periwinkle: '#887cfd',
    palePeach: '#ffd9a2',
    lightPeach: '#ffc2a8',
    cloudyBlueText: '#b5bfcb',
    slateGreyTitleText: '#565c62',
    paleSkyBluePlatinum: '#b3f1ff',
    powderBlueSilver: '#bad7ff',

    primary: '#887cfd',
    accent: '#887cfd',
    secondary: '#f5f7fd',
    info: '#887cfd',
    success: '#abd36c',
    warning: '#ffc107',
    error: '#fa7880',
    danger: '#fa7880',
    bodyBg: '#ffffff',
    bodyColor: '#887cfd',
    componentBg: '#f5f7fd',
    inputBg: '#f5f7fd',
    componentColor: '#8a9499',
    cardCapBg: '#ffffff',
    cardCapColor: '#565c62',
    seriesA: '#0275d8',
    seriesB: '#5bc0de',
    seriesC: '#5cb85c',
    seriesD: '#f0ad4e',
    seriesE: '#e67d4a',
    seriesF: '#d9534f',

    disabled: '#b9bfc3',
    primaryDark: '#6656fc',
    activeBackground: '#5a4afc',

    bronzeColor: '#ffc2a8',
    silverColor: '#bad7ff',
    goldColor: '#ffd9a2',
    platinumColor: '#b3f1ff',

    playerPageElementsColor: '#b7bfca',
    buttonTriggerActive: '#a9b1bc',
    exitButtonColor: '#f87880',
  },
};

export { theme };
