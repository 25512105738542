import { toastsConstants } from '../_constants';

const initialState = {
  idx: 0,
  toastList: [],
};
const toasts = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case toastsConstants.ADD_TOAST:
      return {
        idx: state.idx + 1,
        toastList: [...state.toastList, { ...action.payload, id: state.idx }],
      };
    case toastsConstants.REMOVE_TOAST:
      const toastIdx = state.toastList.findIndex(
        ({ id }) => id === action.payload
      );
      return {
        ...state,
        toastList: [
          ...state.toastList.slice(0, toastIdx),
          ...state.toastList.slice(toastIdx + 1),
        ],
      };
    default:
      return state;
  }
};
export default toasts;
