import React from 'react';
import { QueryView } from '../../_shared/QueryView';
import { useRouteMatch } from 'react-router-dom';
import { useGetEventsIdsForEmployeeQuery } from './queries';
import {
  StyledFC,
  Weight,
  User,
  AchievementCategory,
} from '../../_shared/types';

import BadgesPageView from './View';

const BadgesPage: StyledFC<{
  weights: Weight[];
  categories?: AchievementCategory;
  isMe: boolean;
  user?: User;
}> = ({ className, weights, categories, isMe, user }) => {
  const match = useRouteMatch<{ id: string }>();

  const userID =
    match.params.id !== undefined
      ? +match.params.id
      : Number(user?.employee.id || 0);

  const hookResult = useGetEventsIdsForEmployeeQuery({
    variables: { id: userID },
    fetchPolicy: 'no-cache',
  });

  return (
    <QueryView
      result={hookResult}
      renderData={(data, hookResult) => (
        <BadgesPageView
          weights={weights}
          categories={categories}
          isMe={isMe}
          user={user}
          hookResult={hookResult}
        />
      )}
    />
  );
};

export default BadgesPage;
