import { toastsConstants } from '../_constants';

const addToast = ({
  text,
  className,
  delay,
}: {
  text: string;
  className: string;
  delay: number;
}) => {
  return {
    type: toastsConstants.ADD_TOAST,
    payload: { text, className, delay },
  };
};
const removeToast = (toastId: number) => {
  return {
    type: toastsConstants.REMOVE_TOAST,
    payload: toastId,
  };
};

export const toastsActions = {
  addToast,
  removeToast,
};
