import { authConstants } from '../_constants';

const state = localStorage.getItem('auth');
const initialState = state
  ? JSON.parse(state)
  : {
      accessToken: null,
      refreshToken: null,
      loading: true,
      error: null,
    };

const auth = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case authConstants.FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case authConstants.FETCH_LOGIN_SUCCESS:
      return {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };

    case authConstants.FETCH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case authConstants.FETCH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case authConstants.FETCH_TOKEN_SUCCESS:
      return {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };

    case authConstants.FETCH_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case authConstants.LOGOUT:
      return {};

    default:
      return state;
  }
};
export default auth;
