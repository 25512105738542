import { appConstants } from '../_constants/app-constants';

const initialState = {
  isMobile: false,
  isShowMobileSidebar: false,
};

const app = (
  state = initialState,
  action: {
    type: string;
    payload: boolean;
  }
) => {
  switch (action.type) {
    case appConstants.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case appConstants.SET_IS_SHOW_MOBILE_SIDEBAR:
      return {
        ...state,
        isShowMobileSidebar: action.payload,
      };
    default:
      return state;
  }
};

export default app;
