import { clientConstants } from '../_constants';
import { clientService } from '../_services/client-service';
import { Dispatch } from 'redux';
import { MaybeAssignment, AchievementWeight } from '../_graphql/schema';
import { Players } from '../components/_shared/types';

const getAchievements = (id: number) => (dispatch: Dispatch<any>) => {
  dispatch(request());
  clientService
    .getAchievements(id)
    .then(({ data, errors }) => dispatch(success(data)))
    .catch((error) => dispatch(failure(error)));

  function request() {
    return { type: clientConstants.ACHIEVEMENT_LIST_REQUEST };
  }

  function success(achievements: MaybeAssignment[]) {
    return {
      type: clientConstants.ACHIEVEMENT_LIST_SUCCESS,
      payload: achievements,
    };
  }

  function failure(error: string) {
    return { type: clientConstants.ACHIEVEMENT_LIST_FAILURE, payload: error };
  }
};

const getWeights = () => (dispatch: Dispatch<any>) => {
  dispatch(request());
  return clientService
    .getWeights()
    .then(({ data, errors }) => dispatch(success(data)))
    .catch((error) => dispatch(failure(error)));

  function request() {
    return { type: clientConstants.WEIGHT_LIST_REQUEST };
  }

  function success(weights: AchievementWeight[]) {
    return { type: clientConstants.WEIGHT_LIST_SUCCESS, payload: weights };
  }

  function failure(error: string) {
    return { type: clientConstants.WEIGHT_LIST_FAILURE, payload: error };
  }
};

const getPlayers = () => (dispatch: Dispatch<any>) => {
  dispatch(request());
  clientService
    .getPlayers()
    .then(({ data, errors }) => dispatch(success(data)))
    .catch((error) => dispatch(failure(error)));

  function request() {
    return { type: clientConstants.PLAYERS_LIST_REQUEST };
  }

  function success(players: Players) {
    return { type: clientConstants.PLAYERS_LIST_SUCCESS, payload: players };
  }

  function failure(error: string) {
    return { type: clientConstants.PLAYERS_LIST_FAILURE, payload: error };
  }
};
export const clientActions = {
  getAchievements,
  getWeights,
  getPlayers,
};
