import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateType } from '../../../../_reducers';
import PlayerEventHeader from './PlayerEventHeader';
import { AchievementIdPage } from '../AchievementPage';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { StyledFC, WeightsEnum } from '../../../_shared/types';
import { Event, Me } from '../../../../_graphql/schema';

const AchievementEvent_: StyledFC<{
  event: Event;
  notMe: boolean;
}> = ({ className, event, notMe }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const user: {
    data: Me;
    loading: boolean;
  } = useSelector((state: AppStateType) => state.user);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  const achievement = event.assignment.achievement;

  return (
    <>
      <div
        className={`${className} container`}
        onClick={() => setIsOpenModal(true)}
      >
        <div className="row header-row">
          <PlayerEventHeader date={event.date} type={`${event.__typename}`} />
        </div>
        <div className="row image-row">
          {event.__typename === 'MemoryEvent' ? (
            <div className="col-12 col-sm-12 col-md-10 offset-md-1 memoryDiv">
              <div>
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}uploaded/assignment/image/${event.image}`}
                  alt="logo"
                />
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${achievement.image}`}
                  alt="logo"
                  className="memoryMiniIcon"
                />
              </div>
            </div>
          ) : (
            <div className="col-12 col-sm-8 col-md-8 offset-sm-2 offset-md-2">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}${achievement.image}`}
                alt="logo"
              />
            </div>
          )}
        </div>
        {event.__typename === 'AssignmentEvent' && achievement ? (
          <div className="row name-row">
            <div className="col-12 col-sm-10 offset-sm-1">
              <div className={'name text-center'}>
                <i
                  className={`badges badges-reward-${
                    WeightsEnum[achievement.weight.value]
                  } color-${WeightsEnum[achievement.weight.value]}`}
                />
                {achievement.name}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {event.__typename === 'AssignmentEvent' ? (
          <div className="row description-row">
            <div className="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2">
              <div
                className="description text-center"
                dangerouslySetInnerHTML={createMarkup(achievement.description)}
              />
            </div>
          </div>
        ) : (
          <div className="row description-row">
            <div className="col-12 col-sm-10 col-md-8 offset-sm-1 offset-md-2">
              <div className="description text-center">
                {event.assignment.description}
              </div>
            </div>
          </div>
        )}
      </div>
      {isOpenModal && (
        <AchievementIdPage
          id={achievement.id}
          isMe={true}
          userId={user.data.employee?.id}
          scrollToMemory={event.__typename === 'MemoryEvent'}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  );
};

const xsMedia = bp.down(
  'xs',
  undefined,
  `
    .name-row .name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px !important;
    }
    .description-row .description {
      font-size: 14px !important;
    }
    .achievement-big-event {
      .image-row {
        & > div {
          max-width: 350px;
        }
      }
    }
  `
);

const AchievementEvent = styled(AchievementEvent_)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.paleGrey};
  }
  margin-bottom: 23px;
  padding-bottom: 32px;
  padding-top: 35px;
  border-radius: 20px;
  .image-row {
    margin: 0 0 15px 0;
    display: flex;
    justify-content: center;
    .memoryDiv {
      padding: 0;
      max-width: 340px;
      & > div {
        position: relative;
        padding-bottom: 100%;
        height: 0;
        display: flex;
        justify-content: center;
        border-radius: 15px !important;
        overflow: hidden;
        img {
          position: absolute;
          height: 100%;
          width: auto;
        }
        .memoryMiniIcon {
          width: 50px;
          height: 50px;
          right: 8%;
          bottom: 8%;
        }
      }
    }
    & > div {
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
  .row {
    overflow: unset !important;
  }
  .name-row {
    margin-bottom: 25px;
    .name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #7a8087;
      i {
        font-size: 30px;
        margin-right: 18px;

        &.color-bronze {
          color: ${({ theme }) => theme.colors.bronzeColor};
        }

        &.color-silver {
          color: ${({ theme }) => theme.colors.silverColor};
        }

        &.color-gold {
          color: ${({ theme }) => theme.colors.goldColor};
        }

        &.color-platinum {
          color: ${({ theme }) => theme.colors.platinumColor};
        }
      }
    }
  }

  .description-row {
    .description {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #7a8087;
    }
  }

  ${xsMedia}
`;

export default AchievementEvent;
