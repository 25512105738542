import React from 'react';
import RestoreForm from './RestoreForm';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { StyledFC } from '../_shared/types';
import { useRouteMatch } from 'react-router-dom';
import { AppStateType } from '../../_reducers';

const AuthPage_: StyledFC<{}> = ({ className }) => {
  const match = useRouteMatch();
  const { accessToken } = useSelector((state: AppStateType) => state.auth);

  return accessToken ? (
    <Redirect to={'/'} />
  ) : (
    <div className={className}>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-sm-6 d-flex flex-column justify-content-center align-items-center">
            <picture>
              <source
                media="(min-width: 992px)"
                srcSet="/assets/images/auth/auth@3x.png"
                data-type="image/png"
              />
              <source
                media="(min-width: 768px)"
                srcSet="/assets/images/auth/auth@2x.png"
                data-type="image/png"
              />
              <source
                media="(max-width: 576px)"
                srcSet="/assets/images/auth/auth-mobile.png"
                data-type="image/png"
              />
              <img
                src="/assets/images/auth/auth@3x.png"
                alt="auth"
                data-type="image/png"
              />
            </picture>
          </div>
          <div className="col-12 col-sm-6 d-flex overflow-hidden form-wrapper-column">
            <div className="row align-self-center justify-self-center flex-grow-1">
              <div className="col-12">
                <div className="form-title w-100">The Badges</div>
                <div className="row d-flex justify-content-center h-100">
                  <div className="col-12 col-xl-9">
                    <Switch>
                      <Route
                        path={`${match.url}/signup`}
                        exact
                        component={SignUpForm}
                      />
                      <Route
                        path={`${match.url}/signin`}
                        exact
                        component={SignInForm}
                      />
                      <Route
                        path={`${match.url}/restore`}
                        exact
                        component={RestoreForm}
                      />
                      <Route
                        path={`${match.url}`}
                        render={() => <Redirect to={`${match.url}/signin`} />}
                      />
                      <Route
                        render={() => <Redirect to={`${match.path}/signin`} />}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AuthPage = styled(AuthPage_)`
  height: 100%;
  width: 100%;

  picture {
    max-width: 100%;

    img {
      max-width: 100%;
    }
  }

  .form-title {
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    height: 60px;
  }

  form.auth-page-form {
    a,
    a:hover {
      text-decoration: none;
    }

    > * {
      outline: none;
    }

    button[type='submit'] {
      height: 50px;
      font-weight: 600;
      font-size: 20px;
      transition: color 0.15s ease-in-out;

      &:disabled {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .form-group {
      position: relative;

      &.hasLabel {
        &:before {
          position: absolute;
          top: -20px;
          left: 40px;
          font-size: 12px;
          color: ${({ theme }) => theme.colors.disabled};
        }
      }

      &.email {
        margin-bottom: 20px;

        &.hasLabel {
          &:before {
            content: 'Email';
          }
        }
      }

      &.password {
        margin-top: 40px;
        margin-bottom: 10px;

        &.hasLabel {
          &:before {
            content: 'Пароль';
          }
        }
      }

      input {
        height: 50px;
        padding-left: 40px;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.componentColor};
      }

      .error-text {
        position: absolute;
        left: 40px;
        top: 100%;
        color: ${({ theme }) => theme.colors.danger};
        cursor: default;
      }

      .form-control {
        &:focus {
          border-width: 2px;

          &:not(.is-invalid) {
            border-width: 1px;
            border-color: ${({ theme }) => theme.colors.periwinkle};
            box-shadow: 0 0 2px ${({ theme }) => theme.colors.periwinkle};
          }
        }

        &.is-invalid {
          border-width: 1px;
          border-color: ${({ theme }) => theme.colors.danger};
          box-shadow: 0 0 2px ${({ theme }) => theme.colors.danger};
          background-image: unset;
        }
      }
    }

    .restore-password {
      margin-bottom: 34px;
      margin-right: 22px;

      small {
        font-size: 12px;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.disabled};
        transition: color 0.15s ease-in-out;

        &:hover {
          color: ${({ theme }) => theme.colors.componentColor};
        }
      }
    }

    .link-container {
      margin-top: 40px;

      .link {
        text-decoration: none;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
        transition: color 0.15s ease-in-out;

        &:hover {
          color: ${({ theme }) => theme.colors.primaryDark};
        }
      }
    }
  }

  .router-column {
    position: relative;
  }

  .animation-wrapper {
    position: relative;
    height: 380px;
  }

  .form-wrapper-column {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      content: ' ';
      width: 15px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      content: ' ';
      width: 15px;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }
  }
`;

export default AuthPage;
