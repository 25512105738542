import API from './axios-api';

// const getUser = () => {
//     return API.get(`/user/me`)
// };

const getUser = () => {
  return API.post('', {
    query: `
              query {
                  me {
                      email
                      employee {
                          id
                          name
                          email
                          internalEmail
                          position
                          isActive
                          phone
                          avatar
                          subdivision {
                              name
                              fullName
                            }
                        }
                  }
              }
          `,
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.me : null,
      errors,
    };
  });
};

const register = (email: string) => {
  return API.post('', {
    query: `
            mutation{
                signUp(email: "${email}")
            }`,
    variables: { email },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signUp : null,
      errors,
    };
  });
};

export const userService = {
  getUser,
  register,
};
