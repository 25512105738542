import { authConstants } from '../_constants';

const localStorageMiddleware = () => (
  next: (action: { type: authConstants; payload: any }) => void
) => (action: { type: authConstants; payload: any }) => {
  if (
    [
      authConstants.FETCH_LOGIN_SUCCESS,
      authConstants.FETCH_TOKEN_SUCCESS,
    ].includes(action.type)
  ) {
    if (action.payload) {
      localStorage.setItem('auth', JSON.stringify(action.payload));
    }
  } else if ([authConstants.LOGOUT].includes(action.type)) {
    window.localStorage.removeItem('auth');
  }
  next(action);
};
export default localStorageMiddleware;
