import React, { useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Types from '../../../../_graphql/schema';
import { Me } from '../../../../_graphql/schema';
import { fileUrl } from '../../../../_helpers/file';
import {
  useGetAssignmentQuery,
  useGetAchievementQuery,
  useUpdateAssignmentMutation,
} from './queries';
import { toastsActions } from '../../../../_actions';
import Loader from '../Loader';
import { dateToString } from '../../../_shared/utils';
import styled from 'styled-components';
import { bp } from '@bootstrap-styled/css-mixins';
import { shortAchivment, StyledFC, WeightsEnum } from '../../../_shared/types';
import { DefaultTheme } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../../_reducers';

export type Assignment = { __typename?: 'Assignment' } & Pick<
  Types.Assignment,
  'createdAt' | 'updatedAt' | 'description' | 'image'
> & {
    achievement: { __typename?: 'Achievement' } & Pick<
      Types.Achievement,
      'id' | 'name' | 'description' | 'image'
    > & {
        category: { __typename?: 'Category' } & Pick<Types.Category, 'name'> & {
            project: { __typename?: 'Project' } & Pick<
              Types.Project,
              'description'
            >;
          };
      };
  };

const AchievementPage_: StyledFC<{
  id: number;
  userId?: number;
  achievements?: shortAchivment[];
  isMe?: boolean;
  onClose: () => void;
  achIdFromUrl?: boolean;
  userIdFromUrl?: boolean;
  scrollToMemory?: boolean;
}> = ({
  className,
  id: achievementID,
  achievements,
  isMe,
  onClose = () => {},
  achIdFromUrl,
  userIdFromUrl,
  scrollToMemory,
  userId,
}) => {
  const dispatch = useDispatch();
  const addToast = (toast: {
    text: string;
    className: string;
    delay: number;
  }) => dispatch(toastsActions.addToast(toast));

  const { isMobile } = useSelector((state: AppStateType) => state.app);
  const user: {
    data: Me;
    loading: boolean;
  } = useSelector((state: AppStateType) => state.user);

  const match = useRouteMatch();
  let memoryRef = useRef<HTMLDivElement>(null);
  const [id, setID] = useState(achievementID);
  const [assignment, setAssignment] = useState<Assignment | undefined>();
  const [fromSearchPlayers, setFromSearchPlayers] = useState(false);
  const [momentText, setMomentText] = useState('');
  const [droppedImage, setDroppedImage] = useState<Blob>();
  const [isEditOpened, setIsEditOpened] = useState(false);
  const [canIShare, setCanIShare] = useState(true);
  const [isAchievementReceived, setIsAchievementReceived] = useState(false);
  const {
    data: achievementData,
    loading: achievementLoading,
  } = useGetAchievementQuery({
    variables: { id: +id },
  });

  const getAssignmentQuery = useGetAssignmentQuery({
    variables: {
      employee: userId
        ? userId
        : user.data.employee?.id
        ? user.data.employee?.id
        : 0,
      achievement: id,
    },
  });

  const achievement = achievementData?.achievement;

  const [updateAssignmentMutation] = useUpdateAssignmentMutation();

  useEffect(() => {
    getAssignmentQuery
      .refetch({
        employee: userId
          ? userId
          : user.data.employee?.id
          ? user.data.employee?.id
          : 0,
        achievement: id,
      })
      .then(({ data }) => {
        setAssignment(data.assignment);
      })
      .catch((error) => {
        setAssignment(undefined);
        setIsAchievementReceived(false);
      });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (getAssignmentQuery.data && getAssignmentQuery.data.assignment) {
      setAssignment(getAssignmentQuery.data.assignment);
      setIsAchievementReceived(true);
    } else if (getAssignmentQuery.error) {
      setIsAchievementReceived(false);
    }
  }, [getAssignmentQuery]);

  useEffect(() => {
    if (getAssignmentQuery.data) {
      setIsAchievementReceived(true);
    } else if (getAssignmentQuery.error) {
      setIsAchievementReceived(false);
    }
  }, [getAssignmentQuery]);

  useEffect(() => {
    setFromSearchPlayers(match.path.includes('/search-players'));
  }, [match.path]);

  useEffect(() => {
    if (assignment) {
      const typename = assignment.__typename;
      setIsAchievementReceived(typename === 'Assignment');
      setIsEditOpened(isMobile && !assignment.image);
    }
  }, [assignment, isMobile]);

  useEffect(() => {
    if (assignment) setMomentText(assignment.description);
  }, [assignment, isMobile]);

  useEffect(() => {
    if (scrollToMemory && memoryRef.current) memoryRef.current.scrollIntoView();
  }, [scrollToMemory]);

  useEffect(() => {
    setDroppedImage(undefined);
  }, [isEditOpened]);

  if (achievementLoading || getAssignmentQuery.loading) return <Loader />;

  const onNextClick = () => {
    if (!achievements) return;

    const received = achievements;
    const current = achievements.find((a: shortAchivment) => a.id === +id);
    const currentIndex = received.findIndex(
      (a: shortAchivment) => a.id === current?.id
    );

    setID(
      currentIndex + 1 >= received.length
        ? received[0].id
        : received[currentIndex + 1].id
    );
  };

  const onPrevClick = () => {
    if (!achievements) return;

    const received = achievements;
    const current = achievements.find((a: shortAchivment) => a.id === +id);
    const currentIndex = received.findIndex(
      (a: shortAchivment) => a.id === current?.id
    );

    setID(
      currentIndex - 1 < 0
        ? received[received.length - 1].id
        : received[currentIndex - 1].id
    );
  };

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  const updateMemory = async () => {
    const employee = user.data.employee?.id;
    const achievementId = +id;
    const description = momentText;

    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (droppedImage) {
        reader.readAsDataURL(droppedImage);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      } else {
        resolve();
      }
    })
      .then((image) => {
        if (employee && typeof image === 'string') {
          updateAssignmentMutation({
            variables: {
              employee,
              achievement: achievementId,
              description,
              image: image,
            },
          }).then(() => {
            getAssignmentQuery.refetch();
            setIsEditOpened(false);
            setDroppedImage(undefined);
          });
        } else if (employee) {
          updateAssignmentMutation({
            variables: {
              employee,
              achievement: achievementId,
              description,
            },
          }).then(() => {
            getAssignmentQuery.refetch();
            setIsEditOpened(false);
            setDroppedImage(undefined);
          });
        }
      })
      .catch((error) =>
        console.log('Error in catch of AchievementPage', error)
      );
  };

  return (
    <div className={className}>
      <div className="page-wrapper" onClick={() => onClose()}>
        {!userIdFromUrl && (
          <div className="previous">
            <i
              className="badges badges-left"
              onClick={(e) => {
                e.stopPropagation();
                onPrevClick();
              }}
            />
          </div>
        )}
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-12 col-sm-8">
              <div className="achievement-wrapper w-100">
                <div className="d-block d-sm-none mobile-back">
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose();
                    }}
                    className="badges badges-left"
                  />
                </div>
                <div
                  className="container-fluid"
                  style={{
                    // backgroundColor:
                    //   achievement?.__typename === 'Assignment'
                    //     ? achievement?.color.value
                    //     : '#a7bbff',
                    backgroundColor: achievement?.color.value,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {/**/}
                  <div className="row image-row">
                    <div className="col-8 offset-2 col-sm-6 offset-sm-3 align-items-center justify-content-center d-flex">
                      {isAchievementReceived && achievement?.animation ? (
                        <div className="video-wrap">
                          {assignment ? (
                            <video
                              autoPlay
                              loop
                              muted
                              playsInline
                              key={
                                achievement?.animation &&
                                fileUrl(achievement?.animation)
                              }
                            >
                              <source
                                src={
                                  achievement?.animation &&
                                  fileUrl(achievement?.animation)
                                }
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              src={
                                achievement?.image && fileUrl(achievement.image)
                              }
                              alt={
                                achievement?.image && fileUrl(achievement.image)
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <img
                          src={
                            achievement?.__typename === 'Achievement'
                              ? achievement?.image && fileUrl(achievement.image)
                              : '/assets/images/question-outlined.png'
                          }
                          alt="logo"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row category-row">
                    <div className="col-12 align-items-center justify-content-center d-flex">
                      <span>{achievement?.category.name}</span>
                    </div>
                  </div>
                  <div className="row name-row">
                    <div className="col-12 justify-content-center d-flex w-80">
                      <div>
                        {achievement ? (
                          <>
                            <i
                              className={`badges badges-reward-${
                                WeightsEnum[achievement.weight.value]
                              } color-${WeightsEnum[achievement.weight.value]}`}
                            />
                            {achievement.name}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div className=" row description-row">
                    <div className="col-12 col-sm-8 offset-sm-2 align-items-center justify-content-center d-flex">
                      {achievement ? (
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            achievement.description
                              .split('<br>')
                              .reverse()
                              .join('<br>')
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {achievement?.message ? (
                    <div className="row message-row">
                      <div className=" col-12 align-items-center justify-content-center d-flex">
                        <span>{achievement?.message}</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {isAchievementReceived && isMe && !userIdFromUrl && (
                    <div className="row shareButton-row">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(`Что то там`);
                          if (canIShare) {
                            addToast({
                              text: `Ссылка скопирована`,
                              className: 'bg-violet text-light',
                              delay: 3000,
                            });
                            setCanIShare(false);
                            let t = setTimeout(() => {
                              setCanIShare(true);
                              clearTimeout(t);
                            }, 3000);
                          }
                        }}
                      >
                        Поделиться
                      </button>
                    </div>
                  )}
                </div>
                {(assignment && assignment.image) || isEditOpened ? (
                  <div
                    className="container-fluid memory"
                    style={{
                      backgroundColor: '#fff',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    ref={memoryRef}
                  >
                    <div className="row title-row">
                      <div
                        className="col-12 justify-content-center d-flex w-80"
                        onClick={() => {
                          if (isMe) setIsEditOpened(true);
                        }}
                      >
                        <p>Воспоминание</p>
                        {isMe && <i className="badges badges-pencil-grey" />}
                      </div>
                    </div>
                    <div className="row data-row">
                      {!isEditOpened ? (
                        <>
                          <div className="col-12 justify-content-center d-flex w-80 image">
                            <div className="area no-border">
                              <label
                                className="dropped"
                                htmlFor="image-area"
                                onClick={() => {
                                  if (isMe) setIsEditOpened(true);
                                }}
                              >
                                <img
                                  className="dropped-img"
                                  src={
                                    assignment?.image
                                      ? fileUrl(assignment?.image)
                                      : ''
                                  }
                                  alt={
                                    assignment?.image
                                      ? fileUrl(assignment?.image)
                                      : ''
                                  }
                                />
                              </label>
                            </div>
                          </div>
                          <div
                            className="text col-12 justify-content-center d-flex w-80"
                            onClick={() => {
                              if (isMe) setIsEditOpened(true);
                            }}
                          >
                            <p>{assignment?.description}</p>
                          </div>
                          <div className="date col-12 justify-content-center d-flex w-80">
                            <p>
                              {assignment ? (
                                dateToString(
                                  new Date(assignment.createdAt),
                                  'DD.MM.YYYY'
                                )
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 justify-content-center d-flex w-80 image">
                            <div
                              className="area"
                              onDrop={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDroppedImage(e.dataTransfer.files[0]);
                              }}
                              onDragOver={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              {(assignment && assignment.image) ||
                              droppedImage ? (
                                <></>
                              ) : (
                                <div className="border-upscaled"></div>
                              )}
                              <label
                                className={
                                  (assignment && assignment.image) ||
                                  droppedImage
                                    ? 'dropped'
                                    : ''
                                }
                                htmlFor="image-edit-area"
                              >
                                <img
                                  className="upload-icon"
                                  src="/assets/images/image-big-grey-2@3x.png"
                                  alt=""
                                />
                                <img
                                  className="dropped-img"
                                  src={
                                    droppedImage
                                      ? URL.createObjectURL(droppedImage)
                                      : assignment && assignment.image
                                      ? `https://badges.sysdyn.ru/${assignment.image}`
                                      : ''
                                  }
                                  alt={
                                    droppedImage
                                      ? URL.createObjectURL(droppedImage)
                                      : assignment && assignment.image
                                      ? `https://badges.sysdyn.ru/${assignment.image}`
                                      : ''
                                  }
                                />
                                <div className="upload-info">
                                  {assignment &&
                                  !assignment.image &&
                                  !droppedImage ? (
                                    <>
                                      <u>Выберите фото для воспоминания</u>
                                      <br />
                                      или перетащите его сюда
                                    </>
                                  ) : (
                                    <>
                                      <u>Можете заменить фото</u>
                                      <br />
                                      или перетащить новое фото сюда
                                    </>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="image-edit-area"
                                  accept="image/png, image/jpeg"
                                  onChange={(e) => {
                                    if (e && e.target && e.target.files)
                                      setDroppedImage(e.target.files[0]);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                          <div className="input col-12 justify-content-center d-flex w-80">
                            <textarea
                              name="w3review"
                              rows={3}
                              placeholder="Расскажите о моменте, когда вы получили достижение"
                              maxLength={140}
                              value={momentText}
                              onChange={(e) =>
                                setMomentText(e.target.value.substring(0, 140))
                              }
                            >
                              {' '}
                            </textarea>
                            <span className="counter">
                              {momentText.length}/140
                            </span>
                          </div>
                          <div className="button-panel col-12 justify-content-center d-flex w-80">
                            {isMobile && assignment && !assignment.image ? (
                              <div className="row">
                                <div
                                  className="add"
                                  onClick={updateMemory}
                                  data-disabled={
                                    typeof droppedImage === 'undefined' ||
                                    momentText.length === 0
                                  }
                                >
                                  Добавить
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div
                                  className="cancel"
                                  onClick={() => setIsEditOpened(false)}
                                >
                                  Отмена
                                </div>
                                <div
                                  className="accept"
                                  onClick={updateMemory}
                                  data-disabled={
                                    typeof droppedImage === 'undefined' ||
                                    momentText.length === 0
                                  }
                                >
                                  Сохранить
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              {assignment &&
              !userIdFromUrl &&
              !assignment.image &&
              !isEditOpened &&
              !fromSearchPlayers &&
              !isMobile ? (
                <div className="memory-bar adder justify-content-center">
                  <div className="border-upscaled"></div>
                  <div
                    className="button-add"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditOpened(true);
                    }}
                  >
                    <i className="badges badges-plus" />
                    Добавить воспоминание
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="close-button">
          <i
            onClick={() => onClose()}
            className="badges badges-cross pointer"
          />
        </div>
        {!userIdFromUrl && (
          <div className="next">
            <i
              className="badges badges-right"
              onClick={(e) => {
                e.stopPropagation();
                onNextClick();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const xsMedia = (theme: DefaultTheme) =>
  bp.down(
    'xs',
    undefined,
    `
    .page-wrapper {
      .container {
        width: 100%;
        height: 100%;

        & > .row > div {
          & > div:first-child {
            margin-top: initial;
          }
          & > div:last-child {
            margin-bottom: initial;
          }
        }

        .previous,
        .next,
        .close-button {
          display: none;
        }

        .col-12 {
          padding: 0;
          min-height: 100%;
          height: unset !important;

          .achievement-wrapper {
            border-radius: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: unset !important;

            .data-row .image .area {
              max-width: initial !important;
            }

            .mobile-back {
              z-index: 111;
              position: absolute;
              top: 20px;
              left: 20px;

              i {
                font-size: 24px;
                color: ${({ theme }: { theme: DefaultTheme }) =>
                  theme.colors.white};
              }
            }

            .container-fluid {
              padding: 40px;
            }

            .row {
              color: ${({ theme }: { theme: DefaultTheme }) =>
                theme.colors.white};

              > {
                text-align: center;
              }

              > div {
                text-align: center;
              }

              &.image-row {
                img {
                  margin-bottom: 12px;
                }
              }

              &.weight-row {
                margin-bottom: 12px;

                i {
                  margin-right: 5px;
                }
              }

              &.name-row {
                font-size: 28px;
                margin-bottom: 12px;
                line-height: 0.89;
                & > div {
                  width: 95%;
                  word-wrap: break-word;
                }
              }

              &.category-row {
                font-size: 12px;
                margin-bottom: 20px;
              }

              &.description-row {
                font-size: 14px;
                margin-left: 0;
                margin-right: 0;
                line-height: 1.5;

                margin-bottom: 20px;
              }

              &.message-row {
                color: rgba(255, 255, 255, 0.8);
                font-size: 12px;
              }

              &.received-row {
                font-size: 11px;
                margin-top: 45px;
              }
            }
          }
        }
      }
    }
  `
  );

const AchievementPage = styled(AchievementPage_)`
  position: relative;
  z-index: 3;
  .page-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3c3c3cef;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    .previous,
    .next {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;

      i {
        color: ${({ theme }) => theme.colors.cloudyBlueText};
        font-size: 32px;
        height: 72px;
        width: 72px;
        padding: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }
    }

    .previous {
      left: 10%;
    }

    .next {
      right: 10%;
    }

    .close-button {
      position: fixed;
      right: 10%;
      top: 20px;

      i {
        color: ${({ theme }) => theme.colors.cloudyBlueText};
        font-size: 24px;
        padding: 26px;
        font-weight: bold;
      }
    }

    .container {
      flex-grow: 1;
      height: 100%;
      overflow: scroll;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
      & > .row > div {
        margin: 5% 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .shareButton-row {
        margin: 30px 0 10px 0;
        button {
          outline: none;
          width: 200px;
          height: 40px;
          border-radius: 25px;
          border: none;
          background-color: white;
          margin: 0 auto;
          font-family: Circe, serif;
          font-size: 18px;
          line-height: 1.22;
          color: #7a8087;
          &:hover {
            color: ${({ theme }) => theme.colors.slateGreyTitleText};
          }
        }
      }

      .achievement-wrapper {
        flex-grow: 1;
        border-radius: 31px;
        position: relative;
        overflow: hidden;
        z-index: 5;
        .container-fluid {
          padding: 46px 60px 45px;
          position: relative;
          align-items: center;

          &.memory {
            display: flex;
            flex-direction: column;
            div > div > * {
              color: #7a8087;
              font-size: 20px;
            }

            .title-row > div {
              margin-bottom: 16px;
              align-items: center;
              cursor: ${(props) => (props.isMe ? 'pointer' : 'auto')};
              & > p {
                font-size: 40px;
                font-weight: bold;
                margin: 0;
              }
              & > i {
                display: none;
                color: #b5bfcb;
                font-size: 1.5rem;
                margin-left: 0.5rem;
              }
              &:hover {
                & > i {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .data-row {
              position: relative;
              height: auto;
              width: 100%;
              & > .image {
                height: 0 !important;
                min-height: 0;
                width: 500px;
                padding-bottom: 100%;
                align-items: center;
                //user-select: center; // TODO
                border-radius: 30px;
                overflow: hidden;
                .area {
                  position: absolute;
                  top: 0;
                  height: 100%;
                  width: calc(100% - 40px);
                  max-height: unset;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  overflow: hidden;

                  .border-upscaled {
                    border: 0.5px dashed #7a8087;
                    position: absolute;
                    width: 25%;
                    height: 25%;
                    border-radius: calc(30px / 4);
                    transform: scale(4);
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-size: 18px;
                    color: #b5bfcb;
                    position: relative;
                    margin: 0;

                    .dropped-img {
                      display: none;
                    }
                    .upload-icon {
                      width: auto;
                      height: 35%;
                    }
                    .upload-info {
                      padding: 3%;
                      cursor: pointer;
                    }
                    &.dropped {
                      .dropped-img {
                        display: initial;
                        object-fit: contain;
                      }
                      .upload-icon {
                        display: none;
                      }
                      .upload-info {
                        position: absolute;
                        top: 0;
                        padding: 1rem 0;
                        width: 100%;
                        background-image: linear-gradient(
                          to bottom,
                          rgba(103, 103, 103, 0.6),
                          rgba(103, 103, 103, 0)
                        );
                      }
                    }
                    input {
                      display: none;
                    }
                  }
                }
              }
              & > div {
                margin-top: 40px;
                position: relative;
                p {
                  margin: 0;
                }
                &.date p {
                  color: #b0b6c9;
                }
                &.input {
                  position: relative;
                  min-height: inherit;
                  span {
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 40px);
                    text-align: right;
                    color: #b5bfcb;
                    font-size: 16px;
                    padding: 0.8rem 1.5rem;
                  }
                  textarea {
                    ::-webkit-scrollbar {
                      display: none;
                    }
                    &::placeholder {
                      color: #b5bfcb;
                    }
                    width: 100%;
                    scrollbar-width: none;
                    background-color: #f5f7fd;
                    border-radius: 25px;
                    padding: 0.8rem 1.5rem 2.5rem 1.5rem;
                    font-size: 18px;
                    border: none;
                    overflow: auto;
                    outline: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    resize: none;
                  }
                }
                &.button-panel {
                  .row {
                    width: 100%;
                    justify-content: space-between;
                    & > div {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 25px;
                      font-size: 18px;
                      padding: 0.2rem 0;
                      max-width: 45%;
                      width: 100%;
                      cursor: pointer;
                      height: 40px;
                      &.cancel {
                        border: 1px solid #7a8087;
                        color: #7a8087;
                      }
                      &.accept {
                        border: 1px solid #b7bfca;
                        background-color: #b2adf7;
                        color: #fff;
                        &[disabled] {
                          cursor: default;
                          pointer-events: none;
                          background-color: ${({ theme }) =>
                            theme.colors.cloudyBlueText};
                        }
                      }
                      &.add {
                        border: 1px solid #b7bfca;
                        background-color: #b2adf7;
                        color: #fff;
                        max-width: initial !important;
                        &:disabled {
                          pointer-events: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .row {
          color: ${({ theme }) => theme.colors.white};
          text-align: center;

          > {
            text-align: center;
          }

          div {
            text-align: center;

            span {
              text-align: center;
            }
          }

          &.image-row {
            margin-top: 94px;
            & > div {
              .video-wrap {
                max-height: 250px;
                max-width: 250px;
                padding: 0 !important;
                width: 100%;
                height: 100%;
              }
              margin-bottom: 42px;
            }
            /*i {
                            @include font-size(228px);
                        }*/
            video {
              width: 100%;
              height: auto;
              border-radius: 24%;
              overflow: hidden;
            }

            img {
              object-fit: contain;
              max-height: 250px;
              opacity: 0.5;
              filter: grayscale(1);
              border-radius: 30px;
            }
          }

          &.weight-row {
            margin-bottom: 20px;

            i {
              font-size: 25px;
              height: 25px;
              width: 25px;
              margin-right: 8px;
            }

            span {
              font-size: 15px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.4;
            }
          }

          &.name-row {
            div div {
              max-width: 600px;
              margin: 0 auto 45px;
              font-size: 40px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              justify-content: center;

              .badges {
                margin-right: 16px;
                font-size: 30px;
                &.color-bronze {
                  color: ${({ theme }) => theme.colors.bronzeColor};
                }

                &.color-silver {
                  color: ${({ theme }) => theme.colors.silverColor};
                }

                &.color-gold {
                  color: ${({ theme }) => theme.colors.goldColor};
                }

                &.color-platinum {
                  color: ${({ theme }) => theme.colors.platinumColor};
                }
              }
            }
          }

          &.category-row {
            span {
              color: rgba(255, 255, 255, 0.8);
            }

            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
          }

          &.description-row {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;

            margin-bottom: 20px;
          }

          &.message-row {
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            margin-bottom: 98px;
          }

          &.received-row {
            font-size: 15px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;

            margin-top: 57px;
          }
        }
      }

      .memory-bar {
        display: flex;
        position: relative;
        top: -62px;
        padding-top: 62px;
        width: 100%;

        &.adder {
          .button-add {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
            user-select: none;
            height: 90px;
            width: 100%;
            font-size: 20px;
            z-index: 5;
            & > .badges {
              font-size: 0.8rem;
              margin-right: 0.8rem;
            }
          }
          .border-upscaled {
            border: 0.5px dashed #fff;
            position: absolute;
            width: 25%;
            height: 25%;
            border-radius: calc(31px / 4);
            bottom: 38%;
            transform: scale(4);
          }
        }

        &.panel {
          background-color: #fff;
        }
      }
    }
  }

  ${({ theme }) => xsMedia(theme)}
`;

export default AchievementPage;
