import AchievementEvent from '../_shared/AchievementEvent';
import React, { useEffect } from 'react';
import { Event, Employee } from '../../../_graphql/schema';
import { useGetEventsForEmployeeQuery } from './queries';
import Loader from '../_shared/Loader';

export const EventsForMe: React.FC<{
  notMe: boolean;
  user: Employee;
}> = ({ notMe, user }) => {
  const { data, refetch, loading } = useGetEventsForEmployeeQuery({
    variables: { id: user.id },
    fetchPolicy: 'no-cache',
    //because there are errors while saving event to local Apollo Cache
  });

  useEffect(() => {
    refetch();
  }, [user, refetch]);

  if (loading) return <Loader />;

  return (
    <>
      {data?.employee.events.items.map((event) => {
        return (
          <div key={event.id} style={{ padding: 0 }}>
            <AchievementEvent event={event as Event} notMe={notMe} />
          </div>
        );
      })}
    </>
  );
};

export default EventsForMe;
