import store from '../store';
import { authService } from '../_services';
import { authConstants } from '../_constants';
import { Dispatch } from 'react';

const login = (email: string, password: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    authService
      .login(email, password)
      .then(({ data }) => {
        const { accessToken, refreshToken } = data;
        dispatch(success({ accessToken, refreshToken }));
        resolve({ accessToken, refreshToken });
      })
      .catch((error) => {
        dispatch(failure(error));
        reject(error);
      })
  );

  function request() {
    return { type: authConstants.FETCH_LOGIN_REQUEST };
  }

  function success({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    return {
      type: authConstants.FETCH_LOGIN_SUCCESS,
      payload: { accessToken, refreshToken },
    };
  }

  function failure(error: string) {
    return { type: authConstants.FETCH_LOGIN_FAILURE, payload: error };
  }
};

const logout = () => (dispatch: Dispatch<any>) => {
  const refreshToken = store.getState().auth.refreshToken;

  return new Promise((resolve, reject) => {
    authService
      .logout(refreshToken)
      .then(() => {
        dispatch(actLogout());
        resolve();
      })
      .catch(() => {
        dispatch(actLogout());
        reject();
      });
  });

  function actLogout() {
    return { type: authConstants.LOGOUT };
  }
};

const refreshToken = (refreshToken: string) => (dispatch: Dispatch<any>) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    authService
      .refreshToken(refreshToken)
      .then(({ data }) => {
        const { accessToken, refreshToken } = data;
        dispatch(success({ accessToken, refreshToken }));
        resolve({ accessToken, refreshToken });
      })
      .catch((error) => {
        dispatch(failure(error));
        dispatch(logout());
        reject(error);
      })
  );

  function request() {
    return { type: authConstants.FETCH_TOKEN_REQUEST };
  }

  function success({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    return {
      type: authConstants.FETCH_TOKEN_SUCCESS,
      payload: { accessToken, refreshToken },
    };
  }

  function failure(error: string) {
    return { type: authConstants.FETCH_TOKEN_FAILURE, payload: error };
  }
};

const restore = (email: string) => (dispatch: Dispatch<any>) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    authService
      .restore(email)
      .then(({ data }) => {
        dispatch(success());
        resolve(data);
      })
      .catch((error) => {
        dispatch(failure(error));
        reject(error);
      })
  );

  function request() {
    return { type: authConstants.RESTORE_REQUEST };
  }

  function success() {
    return { type: authConstants.RESTORE_SUCCESS };
  }

  function failure(error: string) {
    return { type: authConstants.RESTORE_FAILURE, payload: error };
  }
};

export const authActions = {
  login,
  logout,
  refreshToken,
  restore,
};
