import * as Types from '../../../_graphql/schema';

import { AchievementPartInProfilePageFragment } from '../PlayerPage/queries';
import gql from 'graphql-tag';
import { AchievementPartInProfilePageFragmentDoc } from '../PlayerPage/queries';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetAssignmentsByUserIdQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetAssignmentsByUserIdQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'name'>
    & { assigned: (
      { __typename?: 'AssignmentPage' }
      & { items: Array<(
        { __typename?: 'Assignment' }
        & Pick<Types.Assignment, 'id' | 'createdAt' | 'image'>
        & { achievement: (
          { __typename?: 'Achievement' }
          & AchievementPartInProfilePageFragment
        ) }
      )> }
    ) }
  ) }
);

export type GetMyEmployeeIdQueryVariables = {};


export type GetMyEmployeeIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & { employee?: Types.Maybe<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id'>
    )> }
  ) }
);


export const GetAssignmentsByUserIdDocument = gql`
    query getAssignmentsByUserId($id: Int!) {
  employee(id: $id) {
    name
    assigned {
      items {
        id
        createdAt
        image
        achievement {
          ...AchievementPartInProfilePage
        }
      }
    }
  }
}
    ${AchievementPartInProfilePageFragmentDoc}`;

/**
 * __useGetAssignmentsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentsByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>(GetAssignmentsByUserIdDocument, baseOptions);
      }
export function useGetAssignmentsByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>(GetAssignmentsByUserIdDocument, baseOptions);
        }
export type GetAssignmentsByUserIdQueryHookResult = ReturnType<typeof useGetAssignmentsByUserIdQuery>;
export type GetAssignmentsByUserIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentsByUserIdLazyQuery>;
export type GetAssignmentsByUserIdQueryResult = ApolloReactCommon.QueryResult<GetAssignmentsByUserIdQuery, GetAssignmentsByUserIdQueryVariables>;
export const GetMyEmployeeIdDocument = gql`
    query getMyEmployeeId {
  me {
    employee {
      id
    }
  }
}
    `;

/**
 * __useGetMyEmployeeIdQuery__
 *
 * To run a query within a React component, call `useGetMyEmployeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEmployeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEmployeeIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEmployeeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyEmployeeIdQuery, GetMyEmployeeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyEmployeeIdQuery, GetMyEmployeeIdQueryVariables>(GetMyEmployeeIdDocument, baseOptions);
      }
export function useGetMyEmployeeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyEmployeeIdQuery, GetMyEmployeeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyEmployeeIdQuery, GetMyEmployeeIdQueryVariables>(GetMyEmployeeIdDocument, baseOptions);
        }
export type GetMyEmployeeIdQueryHookResult = ReturnType<typeof useGetMyEmployeeIdQuery>;
export type GetMyEmployeeIdLazyQueryHookResult = ReturnType<typeof useGetMyEmployeeIdLazyQuery>;
export type GetMyEmployeeIdQueryResult = ApolloReactCommon.QueryResult<GetMyEmployeeIdQuery, GetMyEmployeeIdQueryVariables>;